import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "./../components/Navbars/AdminNavbar.js";
import Sidebar from "./../components/Sidebar/Sidebar.js";
import { createBrowserHistory } from "history"
import routes_adm_cadastro from "./../routes_adm_cadastro";

import Motoboys_Carteira from './admin/motoboy/Carteira'
import Lojas_Carteira from './admin/lojas/Carteira2'
import User_Carteira from './admin/user_app/Carteira'
import api from './../utlites/api'

class Admin extends React.Component {
  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes_adm_cadastro.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes_adm_cadastro[i].layout + routes_adm_cadastro[i].path
        ) !== -1
      ) {
        return routes_adm_cadastro[i].label;
      }

      if (
        this.props.location.pathname.indexOf(
          "admin/motoboy/carteira"
        ) !== -1
      ) {
        return "Carteira do motoboy";
      }

      if (
        this.props.location.pathname.indexOf(
          "admin/loja/carteira"
        ) !== -1
      ) {
        return "Carteira da loja";
      }

      if (
        this.props.location.pathname.indexOf(
          "admin/list_users/carteira"
        ) !== -1
      ) {
        return "Carteira do usuário";
      }

    }
    return "Brand";
  };
  render() {
    return (
      <div>
        <Sidebar
          {...this.props}
          routes={routes_adm_cadastro}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("./../assets/img/brand/logo1.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div style={{ marginTop: 0, }}>
            <Switch>
              {this.getRoutes(routes_adm_cadastro)}
              <Route
                path="/admin/motoboy/carteira"
                component={Motoboys_Carteira}
                key={8}
              />
              <Route
                path="/admin/loja/carteira"
                component={Lojas_Carteira}
                key={10}
              />
              <Route
                path="/admin/list_user/carteira"
                component={User_Carteira}
                key={11}
              />
              <Redirect from="*" to="/admin/unidades" />
            </Switch>
          </div>
          <Container fluid>
            {/* <AdminFooter /> */}
          </Container>
          {this.checaLogin()}
        </div>
      </div>
    );
  }

  checaLogin() {
    const username = localStorage.getItem('@admin_app/username');

    if (username === undefined || username === null) {
      this.props.history.push('/LoginAdmin')
    } else {
      this.checaLoginOline()
    }
  }

  async checaLoginOline() {
    try {
      const data = new FormData();
      data.append('username', localStorage.getItem('@admin_app/username'));
      data.append('senha', localStorage.getItem('@admin_app/usernamePass'));

      const response = await api.post('loginAdm', data);

      if (response.status === 200) {
        if (response.data.error) {

        } else {
          if (response.data.login) {
                       
          } else {
            localStorage.setItem('@admin_app/username', null);
            this.props.history.push('/LoginAdmin')
          }
        }

      } 
    } catch (error) {
      
    }
  }
}

export default Admin;
