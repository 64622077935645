
import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col
} from "reactstrap";

import Logo from 'assets/img/brand/logo1.png'

class AdminNavbar extends React.Component {
  render() {
    return (
        <Navbar
          className="navbar-top navbar-horizontal navbar-dark"
          expand="md"
        >
          <Container className="px-4">
            <NavbarBrand to="/" tag={Link}>
              <img alt="..." src={Logo} style={{width: 50, height: 50}}/>
            </NavbarBrand>
          </Container>
        </Navbar>
    );
  }
}

export default AdminNavbar;
