import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import CadGrupProd from './CadGrupProd';
// import { Container } from './styles';

export default class GruposProd extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    Cadastro: false,
  }

  campos = {
    id: '',
    nome: '',
    ativo: 'Ativo',
    posicao: '',
    imagem: '',
    tipo_grupo: '',
    id_loja: ''
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get(`grupo_prod/${localStorage.getItem('@loja_app/loja')}`);
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !e.target.value });
  };

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <CadGrupProd
            isVisible={this.state.Cadastro}
            Toggle={() => {
              this.setState({ Cadastro: !this.state.Cadastro });
              this.getServidor()
            }}
            handleChange={this.handleChange}
            handleCheck={this.handleCheck}
            campos={this.state}
            atualizaLista={this.getServidor}
          />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* Onde vai ficar o botão */}
                  <Button
                    size="sm"
                    onClick={async () => {
                      this.setState({
                        ...this.state,
                        ...this.campos,
                        Cadastro: true,
                      })
                    }}
                  >
                    <i class="fas fa-plus"></i>
                    <text>Cadastrar</text>
                  </Button>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4">
                  <nav aria-label="...">
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    return (
      <Table className="align-items-center table-flush" hover responsive>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nome</th>
            <th scope="col" />
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map(i => (
            <tr>
              <th scope="row"> {i.id} </th>
              <td scope="row">{i.nome}</td>
              <td scope="row" />
              <td scope="row">
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.setState({
                    ...this.state,
                    ...i,
                    Cadastro: true,
                  })
                }}>Editar</Button>

                <Button outline color="info" 
                  size="sm"
                  onClick={async () => {
                    this.props.history.push({
                      pathname: '/loja/produto',
                      input: {id: i.id},
                    })
                  }}
                >
                  <text>Abrir</text>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}
