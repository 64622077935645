import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, ButtonGroup
} from 'reactstrap';
import api from "./../../../utlites/api";
import mascara from './../../../utlites/mascaraValor'
import moment  from 'moment-timezone';
// import { Container } from './styles';

export default class ViewPedido extends Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
  }

  async componentDidMount() {
    console.log("Oiiii")
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  imprimir = async () => {
    var conteudo = document.getElementById('formImpPedido').innerHTML,
      tela_impressao = window.open('about:blank');

    tela_impressao.document.write(conteudo);
    tela_impressao.window.print();
    tela_impressao.window.close();
    this.props.Toggle()
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_pedido', this.props.campos.id);
      data.append('loja', localStorage.getItem('@loja_app/loja'));
      data.append('tempo_delivery', this.state.inf);


      var response = await api.post('checkPedido', data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div>
        <Modal style={{ width: '10.5cm' }} isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle} style={{ width: '100%', }}>
            <div style={{ width: '100%' }}>
              <ButtonGroup>
                <Button outline color="link" size="sm" onClick={this.props.Toggle}>
                  Sair
               </Button>
                <Button outline color="link" size="sm" onClick={this.imprimir}>
                  Imprimir
               </Button>
                {this.props.campos.isvist === 0 ?
                  <Button outline color="link" size="sm" onClick={this.props.check}>
                    Aceitar pedido
                  </Button>
                  : null}
              </ButtonGroup>
              <h2 style={{ marginTop: 30 }}>Informações do cupom</h2>
            </div>
          </ModalHeader>
          <ModalBody>
            <Form id="formImpPedido" style={{ width: '9cm', marginTop: -20 }}>
              <FormGroup style={{
                width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000',
                borderTopStyle: 'dotted', borderTopWidth: 1, borderTopColor: '#000'
              }}>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Aplicativo FoodJá!</p>
              </FormGroup>
              <FormGroup style={{ width: '100%', display: 'block' }}>
                <Label style={{ width: '100%' }}>Pedido Nº: {this.props.campos.id}</Label>
                <Label style={{ width: '100%' }}>Data/Hora: {moment.tz(this.props.campos.data_pedido, moment.tz.guess()).format("DD/MM/YY HH:mm")}</Label>
                <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />


                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Usuário: </p>
                <Label style={{ width: '100%', marginTop: -10 }}>{this.props.campos.id_usuario} - {this.props.campos.nomeApp} (Telefone de contato: {this.props.campos.telefoneApp})</Label>
                <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />


                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Endereço de entrega</p>
                <Label style={{ width: '100%', marginTop: -10 }}>{this.props.campos.endereco_entrega} - {this.props.campos.complemento}</Label>
                <Label style={{ width: '100%', marginTop: -10 }}>{this.props.campos.id_endereco === null ? "Retirada no local" : null}</Label>
                <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />

                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Produtos / itens</p>
                <div style={{ height: 5, width: 1 }} />

                {this.props.campos.itens.map((i, key) => (
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                      <Label style={{ width: '70%', }}>
                        {i.quantidade}X {i.nome}
                      </Label>
                      <Label>
                        R$ {mascara(eval(parseFloat(i.valor)).toFixed(2))}
                      </Label>
                    </div>
                    <Label style={{ fontSize: 13, marginLeft: 10 }}>
                      {i.adicional}
                    </Label>
                    <Label style={{ fontSize: 13, marginLeft: 10 }}>
                      {i.observacao !== '' && i.observacao !== null ? 'Obs: ' + i.observacao : ''}
                    </Label>
                  </div>
                ))}
                <div style={{ marginTop: 10, width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Informações</p>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor dos produtos:
                    </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.props.campos.valor_produtos)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor de descontos:
                    </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.props.campos.valor_desc)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor da entrega:
                    </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.props.campos.valor_entrega)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor do pedido:
                    </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.props.campos.valor_pedido)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Pagamento:
                    </Label>
                  <Label style={{ width: '70%', textAlign: 'right' }}>
                    {this.props.campos.pagamento}
                  </Label>
                </div>
                <div style={{ marginTop: 30, width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />
              </FormGroup>
              <FormGroup style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }}>
                <p style={{ textAlign: 'center', fontSize: 10 }}>FoodJá tecnologia LTDA!</p>
              </FormGroup>
            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
        </Modal>
      </div>
    );
  }
}
