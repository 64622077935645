import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row, Col, Input, Dropdown,
  DropdownToggle, DropdownMenu, DropdownItem, Badge
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import mascara, { diffDate } from './../../../utlites/mascaraValor'
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import moment from 'moment-timezone'
import io from 'socket.io-client';
import ViewPedido from './view_pedido'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
export default class Extrato_Pedido extends Component {
  //2fbc216a-bb7c-4998-a11a-26c5f734db72
  state = {
    isLoading: false,
    isErro: false,
    dados: [],
    meta: false,
    grupos: [],
    timeNow: '',
    cont: 1,
    isLoading2: false,
    viewPedido: false,
    altStatus: false,
    itens: [],
    startDate: '',
    startDate1: '',
    Tipo: ''
  }

  campos = {
  }

  async componentDidMount() {

    this.setState({
      startDate: new Date(),
      startDate1: new Date(),
    });

  }

  componentWillUnmount() {
    //this.audio.pause();
  }

  async getServidor1() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('data1', `${moment.tz(this.state.startDate, 'Africa/Abidjan').format('yyyy-MM-DD HH:mm')}:00`);
      data.append('data2', `${moment.tz(this.state.startDate1, 'Africa/Abidjan').format('yyyy-MM-DD HH:mm')}:00`);
      data.append('loja', `${localStorage.getItem('@loja_app/loja')}`);

      const response = await api.post(`/pedidos1`, data);
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data
        });

      } else {
        this.setState({ isErro: true });
      }
    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  async handleCheck2(i) {
    this.audio.pause();
    this.setState({ ... this.state, ...i, meta: true, });
  }

  async handleView(i) {
    this.setState({ isErro: false, isLoading2: true });
    try {
      const response = await api.get(`getItensLoja/${i}`);
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          itens: response.data.data,
          viewPedido: true
        });

      } else {
        alert("Erro na comunicação com o servidor")
      }

    } catch (error) {
      alert("Erro na comunicação com o servidor")
    }
    this.setState({ isLoading2: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !e.target.value });
  };

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}

          <ViewPedido
            isVisible={this.state.viewPedido}
            Toggle={() => {
              this.setState({ viewPedido: !this.state.viewPedido });
            }}
            check={() => {
              this.audio.pause();
              this.setState({ ... this.state, meta: true, viewPedido: false });
            }}
            handleChange={this.handleChange}
            campos={this.state}
          />
          <Row >
            <div className="col" >
              <Card className="shadow">
                <CardHeader className="border-0" >
                  {/* Onde vai ficar o botão */}
                  <Row>
                    <Col xs="auto">
                      <div>
                        <div style={{}}>Periodo</div>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={date => this.setState({ startDate: date })}
                          dateFormat="dd/MM/yyyy HH:mm"
                          customInput={<Input type="text" name="nome" size="sm" value={this.state.startDate}
                          />}
                          showTimeInput
                        />
                      </div>

                    </Col>
                    <Col xs="auto">
                      <div>
                        <div style={{ color: '#fff' }}> _</div>
                        a
                      </div>
                    </Col>
                    <Col xs="auto">
                      <div>
                        <div style={{ color: '#fff' }}> _</div>
                        <DatePicker
                          selected={this.state.startDate1}
                          onChange={date => this.setState({ startDate1: date })}
                          customInput={<Input type="text" name="nome" size="sm" value={this.state.startDate}
                          />}
                          dateFormat="dd/MM/yyyy HH:mm"
                          showTimeInput
                        />
                      </div>
                    </Col>
                    <Col xs="auto">
                      <div>
                        <div style={{ color: '#fff' }}> _</div>
                        <Button size="sm" onClick={async () => {
                          await this.setState({ Tipo: 'Geral', filterEmpresa: '', filterMotoboy: '', relatorio: 'Vendas por geral', tipoFiltro: '', })
                          this.getServidor1();
                        }}>
                          <i class="fas fa-search"></i>
                        </Button>                        
                      </div>
                    </Col>
                    <Col xs="auto">
                    </Col>
                    <Col xs="auto">
                    </Col>
                  </Row>
                </CardHeader>


                {this.Corpo()}

                <CardFooter>

                  {this.Rodape()}

                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      if (this.state.Tipo === 'Geral') {
        return this.state.isErro ? this.Erro() : this.Listagem2()
      } else {
        return <div />
      }
    }
  }

  Rodape() {
    if (this.state.Tipo === 'Geral') {
      return <div>
        <Row xs="4">
          <Col>
            <h5>QUANTIDADE: {this.state.dados.length}</h5>
          </Col>
          <Col >
            <h5>TOTAL: R$ {mascara(this.state.dados.reduce((acumulador, valorAtual) => acumulador + valorAtual.valor_pedido, 0).toFixed(2))}</h5>
          </Col>
          <Col >
            <h5>COMISSÃO: R$ {mascara(this.state.dados.reduce((acumulador, valorAtual) => acumulador + valorAtual.valor_comicao, 0).toFixed(2))}</h5>
          </Col>
        </Row>
      </div>
    } else {
      return <div />
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem2() {
    const urlIMG = new VariaveisGlobais();
    return (
      <Table className="align-items-center table-flush" hover responsive>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Cliente</th>
            <th scope="col">Motoboy</th>
            <th scope="col">Data</th>
            <th scope="col">Valor</th>
            <th scope="col">Comissão</th>
            <th scope="col">#</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map((i, key) => (
            <tr key={key}>
              <td scope="row">{i.id}</td>
              <td scope="row">{i.nomeApp.substr(0, 14)} {i.nomeApp.length > 14 ? "..." : ""}</td>
              <td scope="row">{i.nome_moto === null ? 'Retirada' : i.nome_moto.substr(0, 14)} {i.nome_moto === null ? '' : (i.nome_moto.length > 14 ? "..." : "")}</td>
              <td scope="row">{moment.tz(i.data_pedido, moment.tz.guess()).format("DD/MM")}</td>
              <td scope="row">R$ {mascara(eval(parseFloat(i.valor_pedido)).toFixed(2))}</td>
              <td scope="row">R$ {mascara(eval(parseFloat(i.valor_comicao)).toFixed(2))}</td>
              <td>
                <Button outline color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({ ... this.state, ...i });
                    this.handleView(i.id)
                  }}>
                  {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="far fa-eye" />}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

}
