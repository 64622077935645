import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import LojasNavbar from "./../components/Navbars/LojasNavbar.js";
import Sidebar_lojas from "./../components/Sidebar/Sidebar_lojas.js";
import Produto from './lojas/Produtos/Produto'
import SProduto     from './lojas/SProdutos/SProduto'

import routes_loja_cadastro from "./../routes_lojas_cadastro";

class Lojas extends React.Component {

  componentDidMount() {
    document.body.style.backgroundColor = "#fff";
  }

  componentDidUpdate(e) {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.mainContent.scrollTop = 0;
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/loja") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  getBrandText = path => {
    for (let i = 0; i < routes_loja_cadastro.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes_loja_cadastro[i].layout + routes_loja_cadastro[i].path
        ) !== -1
      ) {
        return routes_loja_cadastro[i].label;
      }

      if (
        this.props.location.pathname.indexOf(
          "loja/produto"
        ) !== -1
      ) {
        return "Configuração de cardapio";
      }

      if (
        this.props.location.pathname.indexOf(
          "loja/sprodutos"
        ) !== -1
      ) {
        return "Produtos de montagem";
      }
    }
    return "Brand";
  };

  render() {
    return (
      <div>
        <Sidebar_lojas
          {...this.props}
          routes={routes_loja_cadastro}
          logo={{
            innerLink: "/loja/",
            imgSrc: require("./../assets/img/brand/logo1.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <LojasNavbar
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
          />
          <div style={{ marginTop: 0, }}>
            <Switch>
              {this.getRoutes(routes_loja_cadastro)}
              <Route
                path="/loja/produto"
                component={Produto}
                key={8}
              />
              <Route
                path="/loja/sprodutos"
                component={SProduto}
                key={18}
              />
              <Redirect from="*" to="/loja/monitor" />
            </Switch>
          </div>
          <Container fluid>
            {/* <AdminFooter /> */}
          </Container>
          {this.checaLogin()}
        </div>
      </div>
    );
  }

  checaLogin() {
    const username = localStorage.getItem('@loja_app/loja');

    if (username === undefined || username === null) {
      this.props.history.push('/login')
    } else {

    }
  }
}

export default Lojas;
