import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Alert, InputGroup, InputGroupAddon, InputGroupText, Input
} from 'reactstrap';
import api from "./../../../utlites/api";
// import { Container } from './styles';

export default class CadUser extends Component {


  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: ''
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);
      data.append('login', `${String(this.props.campos.login).replace(`${localStorage.getItem('@loja_app/username')}`, '')}@${localStorage.getItem('@loja_app/username').split('@')[1]}`);
      data.append('senha', this.props.campos.senha);
      data.append('tipo_user', this.props.campos.tipo_user);
      data.append('id_loja', localStorage.getItem('@loja_app/loja'));

      var response;
      if (this.props.campos.id === '') {
        response = await api.put('user_loja', data);
      } else {
        response = await api.post('user_loja', data);
      }

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleExcluir = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);

      var response = await api.delete(`user_loja/${this.props.campos.id}`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Cadastro de usuários</h2></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Id</Label>
                <Input type="text" disabled={true} name="nome" value={this.props.campos.id} />
              </FormGroup>

              <FormGroup>
                <Label>Login</Label>
                <InputGroup>
                  <Input type="text" disabled={this.state.isLoading} name="login" value={String(this.props.campos.login).split('@')[0]} onChange={this.props.handleChange} />
                  <InputGroupAddon addonType="append">
                    <InputGroupText>@{localStorage.getItem('@loja_app/username').split('@')[1]}</InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </FormGroup>

              <FormGroup>
                <Label>Senha *</Label>
                <Input type="password" disabled={this.state.isLoading} name="senha" value={this.props.campos.senha} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Tipo</Label>
                <Input type="select" name="tipo_user" id="exampleSelect" value={this.props.campos.tipo_user} onChange={this.props.handleChange}>
                  <option value="Admin">Admin</option>
                  <option value="Outro">Outro</option>
                </Input>
              </FormGroup>

            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                {this.props.campos.id !== "" ?
                  <Button disabled={this.state.isLoading} color="danger" size="sm" onClick={this.handleExcluir}>
                    Excluir
                  </Button> : null
                }
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Toggle()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
