
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import Login from './lojas/login/Login'

// core components
import AuthNavbar from "./../components/Navbars/AuthNavbarLoja.js";

class AuthLojas extends React.Component {
  componentDidMount() {
    document.body.style.backgroundColor = "#fd5e36";
  }
  componentWillUnmount() {
    document.body.style.backgroundColor = "#fffff";
  }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  render() {
    return (
        <div className="main-content" style={{backgroundColor: '#fd5e36'}}>
          <AuthNavbar />
          <div className=" py-7 py-lg-8">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="5" md="6">
                    <h1 className="text-white">Acesso ao sistema</h1>
                    <p className="text-lead text-light">
                      Utilize suas credenciais de acesso para entrar o sistema
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              
            </div>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Login {... this.props}/>
            </Row>
          </Container>
        </div>
    );
  }
}

export default AuthLojas;
