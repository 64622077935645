import React from "react";

// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

class Login extends React.Component {
  render() {
    return (
      <div>
        <footer >
          <Container>
            <Row className="align-items-center justify-content-xl-between">
              
            </Row>
          </Container>
        </footer>
      </div>
    );
  }
}

export default Login;
