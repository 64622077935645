import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container, CustomInput,
  Row, Form, FormGroup, Label, Input
} from "reactstrap";
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";


export default class Send_Push extends Component {

  state = {
    isLoading: false,
    isErro: false,
    autorizado: false,
    titulo: '',
    msg: '',
    isSucess: false,
    nome_img: '',
    selectedFiles: null,
  }

  componentDidMount() {
    if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {

    } else {
      this.setState({
        isLoading: true
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectFile = event => {

    if (event.target.files[0]) {
      const horas = new Date();
      console.log(`Push-${horas.getDate()}-${horas.getMonth()}-${horas.getFullYear()}-${horas.getHours()}-${horas.getMinutes()}-${horas.getSeconds()}-${horas.getMilliseconds()}.jpeg`);
      this.setState({
        selectedFiles: event.target.files[0],
        nome_img: `Push-${horas.getDate()}-${horas.getMonth()}-${horas.getFullYear()}-${horas.getHours()}-${horas.getMinutes()}-${horas.getSeconds()}-${horas.getMilliseconds()}.jpeg`
      });
    } else {
      this.setState({
        selectedFiles: null,
        nome_img: ''
      });
    }
  }

  handleSend = async e => {
    if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
      var resultado = window.confirm(`Deseja mesmo enviar essa notificação a todos os usuários? lembre se de verificar a mensagem e eventuais erros ortograficos`);
      if (resultado) {
        this.setState({ isLoading: true });
        try {
          const urlIMG = new VariaveisGlobais();
          const data = new FormData();

          data.append('Titulo', this.state.titulo);
          data.append('Msg', this.state.msg);
          data.append('Dest', 'All');

          if (this.state.selectedFiles !== null) {
            data.append('name_img', this.state.name_img);
            data.append('image', this.state.selectedFiles);
            data.append('sevidor_img', urlIMG.getHostIMG + "/");

            const response = await api.post(`/admin/sendPushWithImg`, data);

            console.log("resposta ", response.data)
            if (response.status === 200) {
              this.setState({ isSucess: true })
            } else {
              this.setState({ isLoading: false });
              alert("Ocorreu um erro na requisição")
            }

          } else {

            const response = await api.post(`/admin/sendPush`, data);

            console.log("resposta ", response.data)
            if (response.status === 200) {
              this.setState({ isSucess: true })
            } else {
              this.setState({ isLoading: false });
              alert("Ocorreu um erro na requisição")
            }
          }

        } catch (error) {
          this.setState({ isLoading: false });
          console.log('Teste:: ', error)
          alert("Ocorreu um erro na requisição")
        }
      }
    } else {
      alert('Usuário sem acesso!')
    }
  }

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <Row >
            <div className="col" >
              <Card className="shadow">
                <CardHeader className="border-0" >
                  {/* Onde vai ficar o botão */}
                  <h4>Corpo da mensagem</h4>
                </CardHeader>

                <Form style={{ marginLeft: 25, marginRight: 35 }}>
                  <FormGroup>
                    <Label>Titulo</Label>
                    <Input type="text" disabled={this.state.isLoading} size="sm" name="titulo" value={this.state.titulo} onChange={this.handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Mensagem</Label>
                    <Input type="text" disabled={this.state.isLoading} size="sm" name="msg" value={this.state.msg} onChange={this.handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Imagem (800 x 450 px)</Label>
                    <CustomInput type="file" id="exampleCustomFileBrowser" onChange={this.selectFile} name="img_file" label="Selecione uma imagem" size="sm" />
                  </FormGroup>

                </Form>


                <CardFooter className="py-4 border-0">
                  <nav aria-label="...">
                    <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleSend}>
                      {this.state.isSucess ? "Notificação enviada" : "Enviar"}
                    </Button>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

}