import Unidades from './layouts/admin/unidades/Unidades'
import CadUser from './layouts/admin/user_adm/UserAdm'
import Motoboys from './layouts/admin/motoboy/Motoboy'
import Lojas from './layouts/admin/lojas/Lojas'
import Categorias from './layouts/admin/categorias/Categorias'
import Pedidos_Monitor from './layouts/admin/pedidos/monitor_pedidos'
import Send_Push from './layouts/admin/push/sendpush'
import Send_Push2 from './layouts/admin/push2/sendpush2'
import User_app from './layouts/admin/user_app/User_app'
import Mapa from './layouts/admin/mapa/mapa'
import Cupom from './layouts/admin/cupom/Cupom'
import Extrato_Pedido from './layouts/admin/extrato/extrato_pedidos'
import Entregas_Monitor from './layouts/admin/entregas/monitor_entregas'
import Banner from './layouts/admin/banners/Banner'

var routes_adm_cadastro = [
  {
    path: "/unidades",
    name: "Franquias",
    label: "Cadastro franquias",
    icon: "fas fa-map-pin",
    component: Unidades,
    layout: "/admin"
  },
  {
    path: "/user_adm",
    name: "Usuarios",
    label: "Usuarios administrativos",
    icon: "fas fa-users-cog",
    component: CadUser,
    layout: "/admin"
  },
  {
    path: "/motoboys",
    name: "Motoboys",
    label: "Cadastro de motoboys",
    icon: "fas fa-motorcycle",
    component: Motoboys,
    layout: "/admin"
  },  
  {
    path: "/categorias",
    name: "Categorias",
    label: "Cadastro de Categorias",
    icon: "fas fa-filter",
    component: Categorias,
    layout: "/admin"
  },
  {
    path: "/lojas",
    name: "Lojas",
    label: "Cadastro de lojas",
    icon: "fas fa-store",
    component: Lojas,
    layout: "/admin"
  },
  {
    path: "/monitor_pedidos",
    name: "Monitor",
    label: "Monitor de pedidos",
    icon: "fas fa-shopping-basket",
    component: Pedidos_Monitor,
    layout: "/admin"
  },
  {
    path: "/monitor_entregas",
    name: "Entregas",
    label: "Monitor de entregas",
    icon: "fas fa-bacon",
    component: Entregas_Monitor,
    layout: "/admin"
  },
  {
    path: "/extrato_pedidos",
    name: "Extrato",
    label: "Extrato de pedidos",
    icon: "fas fa-receipt",
    component: Extrato_Pedido,
    layout: "/admin"
  },
  {
    path: "/send_push",
    name: "Push",
    label: "Envio de notificações",
    icon: "fas fa-bell",
    component: Send_Push,
    layout: "/admin"
  },
  {
    path: "/cupons",
    name: "Cupons",
    label: "Cupons de descontos",
    icon: "fas fa-money-check-alt",
    component: Cupom,
    layout: "/admin"
  },
  {
    path: "/list_users",
    name: "Usuarios app",
    label: "Usuarios do aplicativo",
    icon: "fas fa-mobile-alt",
    component: User_app,
    layout: "/admin"
  },
  {
    path: "/location_motoboys",
    name: "Localizações",
    label: "Mapa de navegação de entrgas",
    icon: "fas fa-map-marked-alt",
    component: Mapa,
    layout: "/admin"
  },
  {
    path: "/send_push2",
    name: "Push Motoboy",
    label: "Notificações p/ motoboys",
    icon: "fas fa-bell",
    component: Send_Push2,
    layout: "/admin"
  },
  {
    path: "/banner",
    name: "Banners",
    label: "Banners",
    icon: "fab fa-adversal",
    component: Banner,
    layout: "/admin"
  }

  //<i class="fab fa-adversal"></i>
];
export default routes_adm_cadastro;
