import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert
} from 'reactstrap';
import api from "./../../../utlites/api";
// import { Container } from './styles';

export default class CadGrupProd extends Component {


  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: ''
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);
      data.append('nome', this.props.campos.nome);
      data.append('ativo', this.props.campos.ativo);
      data.append('posicao', this.props.campos.posicao);
      data.append('imagem', this.props.campos.imagem);
      data.append('tipo_grupo', 'Cardapio');
      data.append('id_loja', localStorage.getItem('@loja_app/loja'));

      var response;
      if (this.props.campos.id === '') {
        response = await api.put('grupo_prod', data);
      } else {
        response = await api.post('grupo_prod', data);
      }

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data.data)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleExcluir = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);

      var response = await api.delete(`grupo_prod/${this.props.campos.id}`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Cadastro de grupo de produto</h2></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Id</Label>
                <Input type="text" disabled={true} name="nome" value={this.props.campos.id} />
              </FormGroup>

              <FormGroup>
                <Label>Nome</Label>
                <Input type="text" disabled={this.state.isLoading} name="nome" value={this.props.campos.nome} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Posição</Label>
                <Input type="text" disabled={this.state.isLoading} name="posicao" value={this.props.campos.posicao} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Status</Label>
                <Input type="select" name="ativo" id="exampleSelect" value={this.props.campos.ativo} onChange={this.props.handleChange}>
                  <option value="Inativo">Inativo</option>
                  <option value="Ativo">Ativo</option>
                </Input>
              </FormGroup>

            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                {this.props.campos.id !== "" ?
                  <Button disabled={this.state.isLoading} color="danger" size="sm" onClick={this.handleExcluir}>
                    Excluir
                  </Button> : null
                }
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Toggle()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
