import React from "react";
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col, Spinner, Button
} from "reactstrap";

import InfUser_lojas from './../InfUser/InfUser_lojas';
import Menus_lojas from './Menus_lojas'
import api from './../../utlites/api'
import Logo from 'assets/img/brand/logo1.png'
var ps;

class Sidebar_lojas extends React.Component {
  state = {
    collapseOpen: false,
    myInfo: {},
    isLoading: false,
    isErro: false
  };

  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get(`myloja/${localStorage.getItem('@loja_app/loja')}`);
      console.log("respostass ", response.data.data)
      if (response.status === 200) {
        this.setState({
          myInfo: response.data.data[0]
        });
      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  async handleMudarStatus(NewStatus) {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', localStorage.getItem('@loja_app/loja'));
      data.append('is_aberto', NewStatus);

      const response = await api.post(`mudastatus`, data);
      console.log("respostass ", response.data)
      if (response.status === 200) {
        this.getServidor()
      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }
  // toggles collapse between opened and closed (true/false)
  toggleCollapse = () => {
    this.setState({
      collapseOpen: !this.state.collapseOpen
    });
  };
  // closes the collapse
  closeCollapse = () => {
    this.setState({
      collapseOpen: false
    });
  };

  render() {
    const { bgColor, routes, logo } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: "_blank"
      };
    }
    return (
      <Navbar
        className="navbar-vertical fixed-left navbar-light bg-white"
        expand="md"
        id="sidenav-main"
      >
        <Container fluid>
          {/* Toggler */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={this.toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* Brand */}
          {logo ? (
            <NavbarBrand className="pt-0" {...navbarBrandProps}>
              <img
                alt={logo.imgAlt}
                className="navbar-brand-img"
                src={Logo}
              />
            </NavbarBrand>
          ) : null}
          {/* User */}
          <Nav className="align-items-center d-md-none">
            <InfUser_lojas />
          </Nav>
          {/* Collapse */}
          <Collapse navbar isOpen={this.state.collapseOpen}>
            {/* Collapse header */}
            <div className="navbar-collapse-header d-md-none">
              <Row>
                {logo ? (
                  <Col className="collapse-brand" xs="6">
                    {logo.innerLink ? (
                      <Link to={logo.innerLink}>
                        <img alt={logo.imgAlt} src={logo.imgSrc} />
                      </Link>
                    ) : (
                        <a href={logo.outterLink}>
                          <img alt={logo.imgAlt} src={logo.imgSrc} />
                        </a>
                      )}
                  </Col>
                ) : null}
                <Col className="collapse-close" xs="6">
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleCollapse}
                  >
                    <span />
                    <span />
                  </button>
                </Col>
              </Row>
            </div>
            <div style={{ marginTop: 5, marginBottom: -5, }}>
              {this.botao()}
            </div>
            {/* Navigation */}
            <Menus_lojas fechar={this.toggleCollapse} />
          </Collapse>
        </Container>
      </Navbar>
    );
  }

  botao() {
    if (this.state.isLoading) {
      return this.loading()
    } else {
      if (this.state.isErro) {
        return this.erro()
      } else {
        return this.mudarStatus()
      }
    }
  }

  mudarStatus() {
    return (
      <div>
        {this.state.myInfo.is_aberto === 'Fechado' ?
          (
            <div style={{ width: '100%', }}>
              <Nav navbar>
                <NavItem key={0}>
                  <NavLink
                    to={"#"}
                    tag={NavLinkRRD}
                    onClick={() => {this.handleMudarStatus('Aberto')}}
                    activeClassName="active"
                    style={{ color: 'green' }}
                  >
                    <i class="fas fa-power-off"></i>
                Ativar Serviço
              </NavLink>
                </NavItem>
              </Nav>
            </div>
          ) :
          (
            <div style={{ width: '100%', }}>
              <Nav navbar>
                <NavItem key={0}>
                  <NavLink
                    to={"#"}
                    tag={NavLinkRRD}
                    onClick={() => {this.handleMudarStatus('Fechado')}}
                    activeClassName="active"
                    style={{ color: 'red' }}
                  >
                    <i class="fas fa-power-off"></i>
                Desativar Serviço
              </NavLink>
                </NavItem>
              </Nav>
            </div>
          )
        }
      </div>
    )
  }


  loading() {
    return (
      <div>
        <Nav navbar>
          <NavItem key={0}>
            <NavLink
              to={"#"}
              onClick={this.props.fechar}
            >
              <i style={{ marginTop: -5 }}><Spinner size="sm" color="dark" /></i>
              Carregando
              </NavLink>
          </NavItem>
        </Nav>
      </div>
    )
  }

  erro() {
    return (
      <div>
        <Row style={{ marginLeft: 0, paddingRight: 15, marginBottom: -10 }}>
          <p style={{ textAlign: 'justify', fontSize: 13 }}>
            Ocorreu um erro ao consultar o status da sua loja.
            <a href="#" onClick={() => { this.getServidor() }}> Tente novamente</a>
          </p>
        </Row>
      </div>
    )
  }

}

Sidebar_lojas.defaultProps = {
  routes: [{}]
};

Sidebar_lojas.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired
  })
};

export default Sidebar_lojas;
