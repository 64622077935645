import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, ButtonGroup
} from 'reactstrap';
import api from "./../utlites/api";
import mascara from './../utlites/mascaraValor'
import moment from 'moment-timezone';
// import { Container } from './styles';

export default class ViewPedido2 extends Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    pedido: {},
    itens: []
  }

  async componentDidMount() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const response = await api.get(`pedido/${this.props.match.params.id}`);
      const itens = await api.get(`getItensLoja/${this.props.match.params.id}`);
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          pedido: response.data.data,
          itens: itens.data.data,
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  imprimir = async () => {
    var conteudo = document.getElementById('formImpPedido').innerHTML,
      tela_impressao = window;

    tela_impressao.document.write(conteudo);
    tela_impressao.window.print();
    tela_impressao.window.close();
  }

  async tt() {
    var conteudo = document.getElementById('formImpPedido').innerHTML,
      tela_impressao = window;

    tela_impressao.document.write(conteudo);
    tela_impressao.window.print();
    tela_impressao.window.close();
  }

  imprime() {
    return (
      <div>
        <div style={{ width: '100%', paddingLeft: 10, paddingRight: 10 }}>
          <div>
            <div id="formImpPedido" style={{ width: '100%', marginTop: 5 }}>
              <div style={{
                width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000',
                borderTopStyle: 'dotted', borderTopWidth: 1, borderTopColor: '#000'
              }}>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Aplicativo FoodJá!</p>
              </div>
              <div style={{ width: '100%', display: 'block' }}>
                <Label style={{ width: '100%' }}>Pedido Nº: {this.state.pedido.id}</Label>
                <Label style={{ width: '100%' }}>Data/Hora: {moment.tz(this.state.pedido.data_pedido, moment.tz.guess()).format("DD/MM/YY HH:mm")}</Label>
                <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />


                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Usuário: </p>
                <Label style={{ width: '100%', marginTop: -10 }}>{this.state.pedido.id_usuario} - {this.state.pedido.nomeApp} (Telefone de contato: {this.state.pedido.telefoneApp})</Label>
                <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />

                {this.state.pedido.id_motoboy !== null ?
                  <div>
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Motoboy: </p>
                    <Label style={{ width: '100%', marginTop: -10 }}>{this.state.pedido.id_motoboy} - {this.state.pedido.nome_moto} (Telefone de contato: {this.state.pedido.telefone_moto})</Label>
                    <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />
                  </div>
                  : null

                }

                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Endereço de entrega</p>
                <Label style={{ width: '100%', marginTop: -10 }}>{this.state.pedido.endereco_entrega} - {this.state.pedido.complemento}</Label>
                <Label style={{ width: '100%', marginTop: -10 }}>{this.state.pedido.id_endereco === null ? "Retirada no local" : null}</Label>
                <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />

                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Produtos / itens</p>
                <div style={{ height: 5, width: 1 }} />

                {this.state.itens.map((i, key) => (
                  <div style={{ width: '100%' }}>
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                      <Label style={{ width: '70%', }}>
                        {i.quantidade}X {i.nome}
                      </Label>
                      <Label>
                        R$ {mascara(eval(parseFloat(i.valor)).toFixed(2))}
                      </Label>
                    </div>
                    <Label style={{ fontSize: 13, marginLeft: 10 }}>
                      {i.adicional}
                    </Label>
                    <Label style={{ fontSize: 13, marginLeft: 10 }}>
                      {i.observacao !== '' && i.observacao !== null ? 'Obs: ' + i.observacao : ''}
                    </Label>
                  </div>
                ))}
                <div style={{ marginTop: 10, width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Informações</p>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor dos produtos:
                  </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.state.pedido.valor_produtos)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor de descontos:
                  </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.state.pedido.valor_desc)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor da entrega:
                  </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.state.pedido.valor_entrega)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Valor do pedido:
                  </Label>
                  <Label>
                    R$ {mascara(eval(parseFloat(this.state.pedido.valor_pedido)).toFixed(2))}
                  </Label>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginBottom: 0 }}>
                  <Label style={{ width: '70%', }}>
                    Pagamento:
                  </Label>
                  <Label style={{ width: '70%', textAlign: 'right' }}>
                    {this.state.pedido.pagamento}
                  </Label>
                </div>
                <div style={{ marginTop: 30, width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }} />
              </div>
              <div style={{ width: '100%', alingItens: 'center', borderBottomStyle: 'dotted', borderBottomWidth: 1, borderBottomColor: '#000' }}>
                <p style={{ textAlign: 'center', fontSize: 10 }}>FoodJá tecnologia LTDA!</p>
              </div>
            </div>
          </div>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
        </div>
      </div>
    );
  }

  Carregando() {
    return (
      <div style={{ width: "100%", height: '100vh', alingItens: 'center', display: 'flex', justifyContent: 'center', flexDirection: 'row' }}>
        <div style={{ width: "100%", height: 50, marginTop: 280 }}>
          <p style={{ textAlign: 'center' }}>
            Gerando impressão...
          </p>
        </div>

      </div>
    )
  }

  render() {
    return (
      this.state.isLoading ? this.Carregando()
        : this.imprime()
    )

  }
}
