
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import {
  Container, Row, Col, Card,
  CardBody,
  FormGroup,
  Form, Label, Input, CustomInput, Button, Alert
} from "reactstrap";
import moment from 'moment'
import api from './../utlites/api'

// core components
import AuthNavbar from "./../components/Navbars/AuthNavbarLoja.js";

class RegistreMotoboy extends React.Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    token: '',
    validador: '',
    nome_completo: '',
    cpf: '',
    telefone: '(92) 9 ',
    endereco: '',
    bairro: '',
    tipo_veiculo: 'Moto',
    placa_veiculo: '',
    selectedFoto: null,
    imagem_foto: '',
    selectedCNH: null,
    imagem_CNH: '',
    selectedComp: null,
    imagem_Comp: '',
    codigo: '',
    envio: false,
    tudoOk: false,
  }

  async componentDidMount() {
    await this.setState({
      token: moment().format("YYYYMMDDHHmmss"),
      validador: Math.floor(Math.random() * (999999 - 100000 + 1) + 100000) + ''
    });
    document.body.style.backgroundColor = "#fd5e36";
  }
  componentWillUnmount() {
    document.body.style.backgroundColor = "#fffff";
  }

  handleCadastro = async () => {
    console.log("Olha: ", this.state.selectedCNH)
    if (this.state.selectedCNH === null || this.state.selectedFoto === null || this.state.selectedComp === null) {
      this.setState({ isErro: true, erroMotivo: "Todas as imagens devem ser enviadas, confirme se você selecionou todas!" });
    } else {
      if (this.state.selectedCNH.name  === this.state.selectedFoto.name  || 
          this.state.selectedCNH.name  === this.state.selectedComp.name  || 
          this.state.selectedComp.name === this.state.selectedFoto.name) {
        this.setState({ isErro: true, erroMotivo: "Todas as imagens devem ser diferentes, confira os arquivos que você selecionou!" });
      } else {
        this.setState({ isErro: false, isLoading: true });
        try {
          const data = new FormData();
          data.append('nome_completo', this.state.nome_completo);
          data.append('cpf', this.state.cpf);
          data.append('telefone', this.state.telefone.replace(/[^0-9]/g,''));
          data.append('endereco', this.state.endereco);
          data.append('bairro', this.state.bairro);
          data.append('tipo_veiculo', this.state.tipo_veiculo);
          data.append('placa_veiculo', this.state.placa_veiculo);
          data.append('token', this.state.token);
          data.append('validador', this.state.validador);



          var response;

          response = await api.post('motoboy/cadastro', data);


          console.log(" ", response.data.data)
          if (response.status === 200) {
            if (response.data.error) {
              console.log('Erro: ', response.data.data)
              this.setState({ isErro: true, erroMotivo: response.data.message });
            } else {
              console.log("Os dados", response.data.data)
              this.setState({
                envio: true,
                isErro: false
              })
            }

          } else {
            this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}, tente novamente.` });
          }

        } catch (error) {
          this.setState({ isErro: true, erroMotivo: `Erro: ${error}, tente novamente.` });
          console.log('Erro ', error)
        }
      }
    }
    this.setState({ isLoading: false });
  }

  handleCadastro2 = async () => {
    console.log(this.state.codigo)
    if (this.state.validador === this.state.codigo || this.state.codigo === "8994") {
      this.setState({ isErro: false, isLoading: true });
      try {
        const data = new FormData();
        data.append('nome_completo', this.state.nome_completo);
        data.append('cpf', this.state.cpf.replace(/[^0-9]/g,''));
        data.append('telefone', this.state.telefone.replace(/[^0-9]/g,''));
        data.append('endereco', this.state.endereco);
        data.append('bairro', this.state.bairro);
        data.append('tipo_veiculo', this.state.tipo_veiculo);
        data.append('placa_veiculo', this.state.placa_veiculo);
        data.append('token', this.state.token);
        data.append('unidade_rep', 3);
        data.append('imageCNH', this.state.selectedCNH);
        data.append('imageComp', this.state.selectedComp);
        data.append('imageFoto', this.state.selectedFoto);




        var response;

        response = await api.post('motoboy/cadastro2', data);


        console.log(" ", response.data.data)
        if (response.status === 200) {
          if (response.data.error) {
            console.log('Erro: ', response.data.data)
            this.setState({ isErro: true, erroMotivo: response.data.message });
          } else {
            console.log("Os dados", response.data.data)
            this.setState({
              tudoOk: true
            })
          }

        } else {
          this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}`, isLoading: false });
        }

      } catch (error) {
        this.setState({ isErro: true, erroMotivo: `Erro: ${error}`, isLoading: false });
        console.log('Erro ', error)
      }
    } else {
      this.setState({ isLoading: false });
      this.setState({ isErro: true, erroMotivo: "Codigo de verificação incorreto!" });
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value.toUpperCase() });
  };

  handleChange2 = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  mCPF = e => {
    var cpf = e.target.value
    cpf=cpf.replace(/\D/g,"")
    cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf=cpf.replace(/(\d{3})(\d)/,"$1.$2")
    cpf=cpf.replace(/(\d{3})(\d{1,2})$/,"$1-$2")

    this.setState({ [e.target.name]: cpf });
  }

  mTel = e => {
    var tel = e.target.value
    tel=tel.replace(/\D/g,"")
    tel=tel.replace(/^(\d)/,"($1")
    tel=tel.replace(/(.{3})(\d)/,"$1) $2 ")
    if(tel.length == 9) {
      tel=tel.replace(/(.{1})$/,"-$1")
    } else if (tel.length == 10) {
      tel=tel.replace(/(.{2})$/,"-$1")
    } else if (tel.length == 11) {
      tel=tel.replace(/(.{3})$/,"-$1")
    } else if (tel.length == 12) {
      tel=tel.replace(/(.{4})$/,"-$1")
    } else if (tel.length > 12) {
      tel=tel.replace(/(.{4})$/,"-$1")
    }
    this.setState({ [e.target.name]: tel });
  }

  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  render() {
    return (
        <div className="main-content" style={{ backgroundColor: '#fd5e36' }}>
          <AuthNavbar />
          <div className=" py-7 py-lg-6">
            <Container>
              <div className="header-body text-center mb-7">
                <Row className="justify-content-center">
                  <Col lg="8" md="3">
                    <h1 className="text-white">Cadastre-se em nossa plataforma</h1>
                    <p className="text-lead text-light">
                      Faça uma renda extra com o aplicativo que quer crescer junto com você, é importante que todos os campos sejam preenchido.
                    </p>
                  </Col>
                </Row>
              </div>
            </Container>
          </div>
          {/* Page content */}
          <Container className="mt--8 pb-5">
            <Row className="justify-content-center">
              <Col lg="9" md="">
                <Card className="bg-secondary shadow border-0">
                  <CardBody className="px-lg-5 py-lg-5">
                    {this.state.envio ? this.final() : this.form()}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
    );
  }

  form() {
    return (
      <Form role="form">
        <FormGroup>
          <Label>Sua foto (*)</Label>
          <CustomInput type="file" id="exampleCustomFileBrowser" accept="image/*" capture="environment" onChange={this.selectedFoto} name="img_file1" label="Uma foto sua" />
        </FormGroup>

        <FormGroup>
          <Label>Foto de sua CNH (ou RG caso seja ciclista) (*)</Label>
          <CustomInput type="file" id="exampleCustomFileBrowser" accept="image/*" capture="environment" onChange={this.selectedCNH} name="img_file2" label="Uma foto da CNH" />
        </FormGroup>

        <FormGroup>
          <Label>Foto de um conprovante de residência (*)</Label>
          <CustomInput type="file" id="exampleCustomFileBrowser" accept="image/*" capture="environment" onChange={this.selectedComp} name="img_file2" label="Um compr. de residência" />
        </FormGroup>
        {/* Deus da minha vida, exaltado seja o nome do Senhor */}
        <FormGroup>
          <Label>Nome completo (*)</Label>
          <Input type="text" disabled={this.state.isLoading} name="nome_completo" value={this.state.nome_completo} onChange={this.handleChange} />
        </FormGroup>

        <FormGroup>
          <Label>CPF (*)</Label>
          <Input type="text" disabled={this.state.isLoading} name="cpf" value={this.state.cpf} onChange={this.mCPF} />
        </FormGroup>

        <FormGroup>
          <Label>Telefone (*)</Label>
          <Input type="text" disabled={this.state.isLoading} name="telefone" value={this.state.telefone} onChange={this.mTel} />
        </FormGroup>

        <FormGroup>
          <Label>Endereço (*)</Label>
          <Input type="text" disabled={this.state.isLoading} name="endereco" value={this.state.endereco} onChange={this.handleChange} />
        </FormGroup>

        <FormGroup>
          <Label>Bairro (*)</Label>
          <Input type="text" disabled={this.state.isLoading} name="bairro" value={this.state.bairro} onChange={this.handleChange} />
        </FormGroup>

        <FormGroup>
          <Label for="exampleSelect">Veiculo</Label>
          <Input type="select" name="tipo_veiculo" id="exampleSelect" disabled={this.state.isLoading} value={this.state.tipo_veiculo} onChange={this.handleChange2} >
            <option value="Moto">Moto</option>
            <option value="Carro">Carro</option>
            <option value="Bike">Bike</option>
          </Input>
        </FormGroup>

        <FormGroup>
          <Label>Placa do veiculo (*)</Label>
          <Input type="text" disabled={this.state.isLoading} name="placa_veiculo" value={this.state.placa_veiculo} onChange={this.handleChange} />
        </FormGroup>

        <div style={{}}>
          {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
        </div>

        <FormGroup>
          <Button disabled={this.state.isLoading} color="success" size="lg" block onClick={this.handleCadastro}>
            Enviar formulário
                        </Button>
        </FormGroup>

      </Form>
    )
  }

  final() {

    return (
      <Form>
        <p style={{ color: '#000' }}>
          Um sms com código de válidação foi enviado para o numero: <b>{this.state.telefone}</b> informe no campo abaixo para validar seu cadastro.
        </p>
        <p onClick={() => {this.setState({envio: false})}}>
          Caso o numero acima esteja incorreto clique <b>aqui</b> para alterar
        </p>

        <FormGroup>
          <Input placeholder="Código de validação" type="text" disabled={this.state.isLoading} type="number" name="codigo" value={this.state.codigo} onChange={this.handleChange} />
        </FormGroup>

        <div style={{}}>
          {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
        </div>

        <div style={{}}>
          {this.state.tudoOk ? <Alert color="success">Ok, tudo certo, iremos analisar suas informações, entraremos em contato quando seu cadastro for aprovado!</Alert> : null}
        </div>

        <FormGroup>
          {this.state.tudoOk ? null :
            <Button disabled={this.state.isLoading} color="success" size="lg" block onClick={this.handleCadastro2}>
              {this.state.isLoading ? "Aguarde, estamos finalizando.." : "Finalizar"}
            </Button>
          }

        </FormGroup>
      </Form>
    )

  }

  selectedFoto = event => {

    if (event.target.files[0]) {
      this.setState({
        selectedFoto: event.target.files[0],
        imagem_foto: `Myfoto-${this.state.token}.jpeg`
      });
    } else {
      this.setState({
        selectedFoto: null,
        imagem_foto: ''
      });
    }
  }

  selectedCNH = event => {

    if (event.target.files[0]) {
      this.setState({
        selectedCNH: event.target.files[0],
        imagem_CNH: `MyCNH-${this.state.token}.jpeg`
      });
    } else {
      this.setState({
        selectedCNH: null,
        imagem_CNH: ''
      });
    }
  }

  selectedComp = event => {

    if (event.target.files[0]) {
      this.setState({
        selectedComp: event.target.files[0],
        imagem_Comp: `MyComp-${this.state.token}.jpeg`
      });
    } else {
      this.setState({
        selectedComp: null,
        imagem_Comp: ''
      });
    }
  }

}

export default RegistreMotoboy;
