import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, CustomInput, Media
} from 'reactstrap';
import api from "./../../../utlites/api";
import md5 from 'js-md5'
// import { Container } from './styles';
import Map from './../../../components/Maps/MapaBusca'

export default class CadBanner extends Component {


  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: '',
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
      this.setState({ isErro: false, isLoading: true });
      try {
        console.log('Nome', this.props.campos.nome_img)
        const data = new FormData();
        data.append('id', this.props.campos.id);
        data.append('titulo', this.props.campos.titulo);
        data.append('nome_img', this.props.campos.nome_img);
        data.append('id_loja', this.props.campos.id_loja);
        data.append('lat_anuncio', this.props.campos.lat_anuncio);
        data.append('lng_anuncio', this.props.campos.lng_anuncio);
        data.append('raio_anuncio', this.props.campos.raio_anuncio);
        data.append('tipo', this.props.campos.tipo);
        if (this.props.campos.selectedFiles !== null && this.props.campos.selectedFiles !== undefined) {
          data.append('image', this.props.campos.selectedFiles);
        }

        var response;
        if (this.props.campos.id === '') {
          response = await api.put('banner', data);
        } else {
          response = await api.post('banner', data);
        }

        console.log("resposta ", response.data)
        if (response.status === 200) {
          if (response.data.error) {
            console.log('Erro: ', response.data.data)

            this.setState({ isErro: true, erroMotivo: `Erro interno: ${response.data.message}` });
          } else {
            this.props.Toggle();
          }

        } else {
          this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
        }

      } catch (error) {
        this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
        console.log('Erro ', error)
      }
      this.setState({ isLoading: false });
    } else {
      alert('Usuário sem acesso!')
    }
  }

  handleExcluir = async () => {
    if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {

      this.setState({ isErro: false, isLoading: true });
      try {
        const data = new FormData();
        data.append('id', this.props.campos.id);

        var response = await api.delete(`banner/${this.props.campos.id}`, data);

        console.log("resposta ", response.data.data)
        if (response.status === 200) {
          if (response.data.error) {
            console.log('Erro: ', response)
            this.setState({ isErro: true, erroMotivo: `Erro interno` });
          } else {
            this.props.Toggle();
          }

        } else {
          this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
        }

      } catch (error) {
        this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
        console.log('Erro ', error)
      }
      this.setState({ isLoading: false });
    } else {
      alert('Usuário sem acesso!')
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Cadastro de banner</h2></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Id</Label>
                <Input type="text" disabled={true} name="nome" value={this.props.campos.id} />
              </FormGroup>

              <FormGroup>
                <Label>Imagem (Banner: 1080 : 300) (Anuncio: 800 : 800)*</Label>
                <CustomInput type="file" id="exampleCustomFileBrowser" onChange={this.props.selectFile} name="img_file" label="Selecione uma imagem" size="sm" />
              </FormGroup>

              <FormGroup>
                <Label>Titulo</Label>
                <Input type="text" disabled={this.state.isLoading} name="titulo" value={this.props.campos.titulo} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Id da loja</Label>
                <Input type="text" disabled={this.state.isLoading} name="id_loja" value={this.props.campos.id_loja} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Raio de anúncio</Label>
                <Input type="text" disabled={this.state.isLoading} name="raio_anuncio" value={this.props.campos.raio_anuncio} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Tipo</Label>
                <Input type="select" name="tipo" id="exampleSelect" value={this.props.campos.tipo} onChange={this.props.handleChange}>
                  <option value="Banner">Banner</option>
                  <option value="Anúncio">Anúncio</option>
                </Input>
              </FormGroup>

              <FormGroup style={{ height: 250, }}>
                <Label>Localização * </Label>
                <div style={{ width: '100%', height: 250, }}>
                  <Map
                    google={this.props.google}
                    center={{ lat: this.props.lat, lng: this.props.log }}
                    height={300}
                    zoom={17}
                    pegaEndereco={(Endereco) => {
                      this.props.getEndereco(Endereco)
                      console.log('Eiii', Endereco);
                    }}
                    pegaCoodenadas={(lat, log) => {
                      this.props.getCoodenadas(lat, log)
                      console.log('Eiii: ', lat + ' / ' + log);
                    }}
                  />
                </div>
              </FormGroup>

              <FormGroup>
                <div style={{width: 5, height: 50}} />
              </FormGroup>

            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                {this.props.campos.id !== "" ?
                  <Button disabled={this.state.isLoading} color="danger" size="sm" onClick={this.handleExcluir}>
                    Excluir
                  </Button> : null
                }
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Toggle()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
