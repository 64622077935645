import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import CadUnidade from './CadMotoboy';
import mascara, { diffDate } from './../../../utlites/mascaraValor'
// import { Container } from './styles';

export default class Motoboy extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    dadosOr: [],
    Cadastro: false,
    unidade: []
  }

  campos = {
    id: '',
    nome_completo: '',
    cpf: '',
    rg: '',
    telefone: '',
    endereco: '',
    bairro: '',
    tipo_veiculo: '',
    placa_veiculo: '',
    cnh: '',
    indicacao: '',
    observacao: '',
    status: '',
    unidade_rep: ''
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get('motoboy');
      const resp_unidade = await api.get('unidade');

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data,
          dadosOr: response.data.data,
          unidade: resp_unidade.data.data
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };



  render() {
    return (
      <div>
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <CadUnidade
            isVisible={this.state.Cadastro}
            Toggle={(e) => {
              var temp = this.state.dados.map(dados => (dados.id == e.id ? { ...e, carteira: dados.carteira } : dados));
              console.error('EEE',e)
              this.setState({ Cadastro: !this.state.Cadastro, dados: temp });
              //this.getServidor()
            }}
            Cancel={() => {
              this.setState({ Cadastro: !this.state.Cadastro })
            }}
            handleChange={this.handleChange}
            campos={this.state}
            atualizaLista={this.getServidor}
            unidades={this.state.unidade}
          />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* Onde vai ficar o botão */}
                  <div id="xxdde" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      size="sm"
                      onClick={async () => {
                        if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
                          this.setState({
                            ...this.state,
                            ...this.campos,
                            Cadastro: true,
                          })
                        } else {
                          alert('Usuário sem acesso!')
                        }
                      }}
                    >
                      <i class="fas fa-plus"></i>
                      <text>Cadastrar</text>
                    </Button>

                    <div style={{ display: 'flex' }}>
                      <Dropdown size="sm" direction="left" isOpen={this.state.dropdownOpen1} toggle={() => { this.setState({ dropdownOpen1: !this.state.dropdownOpen1 }) }}>
                        <DropdownToggle caret>
                          <i class="fas fa-filter"></i>
                        </DropdownToggle>
                        <DropdownMenu container="xxdde">
                          <DropdownItem onClick={this.filterSaldoMais}>Com saldo positivo</DropdownItem>
                          <DropdownItem onClick={this.filterSaldoMenos}>Com saldo negativo</DropdownItem>
                          <DropdownItem onClick={this.filterAtivo}>Motoboy ativos</DropdownItem>
                          <DropdownItem onClick={this.filterInativo}>Motoboy inativos</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.filterReturn}>Desfazer filtro</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>

                      <Dropdown size="sm" direction="left" isOpen={this.state.dropdownOpen2} toggle={() => { this.setState({ dropdownOpen2: !this.state.dropdownOpen2 }) }}>
                        <DropdownToggle caret>
                          <i class="fas fa-sort-amount-down"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => {
                            var temp = this.state.dadosOr;
                            this.setState({
                              dados: temp.sort((a, b) => (
                                b.carteira - a.carteira
                              ))
                            })
                          }}>Por maior saldo</DropdownItem>
                          <DropdownItem onClick={() => {
                            var temp = this.state.dadosOr;
                            this.setState({
                              dados: temp.sort((a, b) => (
                                a.carteira - b.carteira
                              ))
                            })
                          }}>Por menor saldo</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => {
                            var temp = this.state.dadosOr;
                            this.setState({
                              dados: temp.sort((a, b) => (
                                a.id - b.id
                              ))
                            })
                          }}>Desfazer ordenação</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>

                  <Row style={{ alignItems: 'center', marginLeft: 1, fontSize: 12, marginTop: 20 }}>
                    {this.Status(false)}
                    Inativo
                    <div style={{ width: 25 }} />
                    {this.Status(true)}
                    Ativo
                  </Row>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4">
                  <nav aria-label="...">

                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>

      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    return (
      <Table className="align-items-center table-flush" hover size="sm" responsive>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col"></th>
            <th scope="col">Nome</th>
            <th scope="col">Saldo</th>
            <th scope="col">Telefone</th>
            <th scope="col">Unidade</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map(i => (
            <tr>
              <th scope="row"> {i.id} </th>
              <th scope="row"> {this.Veiculo(i.tipo_veiculo)} </th>
              <td scope="row"> <Row style={{ alignItems: 'center' }}>
                {this.Status(i.status === 'Ativo' ? true : false)} {i.nome_completo}
              </Row>
              </td>
              <td scope="row">R$ {i.carteira >= 0 ? '' : '-'} {mascara(eval(parseFloat(i.carteira)).toFixed(2))}</td>
              <td scope="row">{i.telefone}</td>
              <td scope="row">{i.unidade_nome}</td>
              <td scope="row">
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.setState({
                    ...this.state,
                    ...i,
                    Cadastro: true,
                  })
                }}>Editar</Button>
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.props.history.push({
                    pathname: '/admin/motoboy/carteira',
                    input: { id: i.id, nome: i.nome_completo },
                  })
                }}
                >Carteira</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  filterSaldoMais = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.carteira > 0
      ))
    })
  }

  filterSaldoMenos = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.carteira < 0
      ))
    })
  }

  filterAtivo = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.status === 'Ativo'
      ))
    })
  }

  filterInativo = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.status !== 'Ativo'
      ))
    })
  }

  filterReturn = () => {
    var temp = this.state.dadosOr;
    this.setState({ dados: temp })
  }

  Veiculo(Tipo) {
    if (Tipo.toUpperCase() === 'CARRO') {
      return (
        <a href="#" style={{color: '#646464'}}>
          <i class="fas fa-car"></i>      
        </a>
      )
    } else if(Tipo.toUpperCase() === 'BIKE') {
      return (
        <a href="#" style={{color: '#cf8d61'}}>
          <i class="fas fa-bicycle"></i>      
        </a>
      )
    } else {
      return (
        <a href="#"  style={{color: '#6367b7'}}>
          <i class="fas fa-motorcycle"></i>      
        </a>
      )
    }
  }

  Status(isativo) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: isativo ? '#D4EDDA' : '#F8D7DA',
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }

}
