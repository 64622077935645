import React, { Component, useState } from 'react';
import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    Form, FormGroup, Label, Input, Alert, CustomInput
} from 'reactstrap';
import Map from './../../../components/Maps/MapaBusca'
import api from "./../../../utlites/api";
// import { Container } from './styles';

// Link que pode ajudar
// https://www.digitalocean.com/community/tutorials/how-to-integrate-the-google-maps-api-into-react-applications
// https://dev.to/matheusribak/ola-coleguinha-vamos-aprender-a-usar-a-api-google-maps-com-react-18h5?fbclid=IwAR3g1uHXFOTYQtbzqPfD_cpdAS03uWkrWmZuBK6msH0WlzyLCWtoXy5pYvI
// https://imranhsayed.medium.com/google-maps-in-react-autocomplete-location-search-draggable-marker-marker-infobox-565ab8e8cf22
// https://github.com/imranhsayed/google-maps-in-react

export default class CadUnidade extends Component {
    //Deus da minha vida, so tus es Deus, louvado seja seu nome

    state = {
        modal: false,
        backdrop: true,
        keyboard: true,
        isLoading: false,
        isErro: false,
        erroMotivo: ''
    }

    changeBackdrop = e => {
        let value = e.target.value;
        if (value !== 'static') {
            value = JSON.parse(value);
        }
        this.setState({ backdrop: value })
    }

    changeKeyboard = e => {
        this.setState({ keyboard: e.currentTarget.checked })
    }

    handleCadastro = async () => {
        this.setState({ isErro: false, isLoading: true });
        try {
            const data = new FormData();
            data.append('id', this.props.campos.id);
            data.append('nome', this.props.campos.nome);
            data.append('descricao', this.props.campos.descricao);
            data.append('name_img', this.props.campos.name_img);
            data.append('endereco', this.props.campos.endereco);
            data.append('latitude', this.props.campos.latitude);
            data.append('longitude', this.props.campos.longitude);
            data.append('raio', this.props.campos.raio);
            data.append('taxa_entrega', this.props.campos.taxa_entrega);
            data.append('pedido_minimo', this.props.campos.pedido_minimo);
            data.append('comissao', this.props.campos.comissao);
            data.append('categorias', this.props.campos.categorias);
            data.append('km_entrega', this.props.campos.km_entrega);
            data.append('tempo_entrega', this.props.campos.tempo_entrega);
            data.append('sid', this.props.campos.sid);
            data.append('telefone', this.props.campos.telefone);
            data.append('franquia_rep', this.props.campos.franquia_rep);
            data.append('status', this.props.campos.status);
            data.append('tipo_delivery', this.props.campos.tipo_delivery);
            data.append('em_promo', this.props.campos.em_promo);
            data.append('valor_desc', String(this.props.campos.valor_desc).replace(",", "."));
            data.append('entrega_free', this.props.campos.entrega_free);
            data.append('percet_entrega', String(this.props.campos.percet_entrega).replace(",", "."));
            data.append('reduz_entregua', String(this.props.campos.reduz_entregua).replace(",", "."));
            if (this.props.campos.selectedFiles !== null && this.props.campos.selectedFiles !== undefined) {
                data.append('image', this.props.campos.selectedFiles);
            }

            var response;
            if (this.props.campos.id === '') {
                response = await api.put('lojas', data);
            } else {
                response = await api.post('lojas', data);
            }

            console.log("resposta ", response.data)
            if (response.status === 200) {
                if (response.data.error) {
                    console.log('Erro: ', response.data.data)
                    this.setState({ isErro: true, erroMotivo: response.data.message });
                } else {
                    if (this.props.campos.id === '') {
                        this.props.atualiza();
                    }
                    else {
                        this.props.Toggle(response.data.data);
                    }

                }

            } else {
                this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
            }

        } catch (error) {
            this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
            console.log('Erro: ', error)
        }
        this.setState({ isLoading: false });
    }

    handleExcluir = async () => {
        // this.setState({ isErro: false, isLoading: true });
        // try {
        //     const data = new FormData();
        //     data.append('id', this.props.campos.id);

        //     var response = await api.delete(`lojas/${this.props.campos.id}`, data);

        //     console.log("resposta ", response.data.data)
        //     if (response.status === 200) {
        //         if (response.data.error) {
        //             console.log('Erro: ', response)
        //             this.setState({ isErro: true, erroMotivo: `Erro interno` });
        //         } else {
        //             this.props.Toggle();
        //         }

        //     } else {
        //         this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
        //     }

        // } catch (error) {
        //     this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
        //     console.log('Erro ', error)
        // }
        // this.setState({ isLoading: false });
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
                    <ModalHeader toggle={this.toggle}><h2>Cadastro de lojas</h2></ModalHeader>
                    <ModalBody>
                        <Form>
                            <FormGroup>
                                <Label>Id</Label>
                                <Input type="text" disabled={true} name="id" value={this.props.campos.id} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Imagem *</Label>
                                <CustomInput type="file" id="exampleCustomFileBrowser" onChange={this.props.selectFile} name="img_file" label="Selecione uma imagem" />
                            </FormGroup>

                            <FormGroup>
                                <Label>Nome *</Label>
                                <Input type="text" disabled={this.state.isLoading} name="nome"
                                    value={this.props.campos.nome} onChange={this.props.handleChange} required />
                            </FormGroup>

                            <FormGroup>
                                <Label>Descricao</Label>
                                <Input type="text" disabled={this.state.isLoading} name="descricao"
                                    value={this.props.campos.descricao} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Id de indentificação *</Label>
                                <Input type="text" disabled={this.state.isLoading} name="sid"
                                    value={this.props.campos.sid} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup style={{ height: 250, }}>
                                <Label>Localização * </Label>
                                <div style={{ width: '100%', height: 250, }}>
                                    <Map
                                        google={this.props.google}
                                        center={{ lat: this.props.lat, lng: this.props.log }}
                                        height={300}
                                        zoom={17}
                                        pegaEndereco={(Endereco) => {
                                            this.props.getEndereco(Endereco)
                                            console.log('Eiii', Endereco);
                                        }}
                                        pegaCoodenadas={(lat, log) => {
                                            this.props.getCoodenadas(lat, log)
                                            console.log('Eiii: ', lat + ' / ' + log);
                                        }}
                                    />
                                </div>
                            </FormGroup>

                            <FormGroup style={{ marginTop: 100 }}>
                                <Label>Endereco * </Label>
                                <Input type="text" disabled={true} name="endereco"
                                    value={this.props.campos.endereco} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Raio de atendimento (Km)</Label>
                                <Input type="text" disabled={this.state.isLoading} name="raio"
                                    value={this.props.campos.raio} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Telefone</Label>
                                <Input type="text" disabled={this.state.isLoading} name="telefone"
                                    value={this.props.campos.telefone} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Comissao (%)</Label>
                                <Input type="text" disabled={this.state.isLoading} name="comissao"
                                    value={this.props.campos.comissao} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Taxa de entrega (R$)</Label>
                                <Input type="text" disabled={this.state.isLoading} name="taxa_entrega"
                                    value={this.props.campos.taxa_entrega} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Distância base de entrega (Km)</Label>
                                <Input type="text" disabled={this.state.isLoading} name="km_entrega"
                                    value={this.props.campos.km_entrega} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Pedido minimo  (R$)</Label>
                                <Input type="text" disabled={this.state.isLoading} name="pedido_minimo"
                                    value={this.props.campos.pedido_minimo} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label>Tempo medio de entrega</Label>
                                <Input type="text" disabled={this.state.isLoading} name="tempo_entrega"
                                    value={this.props.campos.tempo_entrega} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Categorias</Label>
                                <Input type="text" disabled={true} name="categorias"
                                    value={String(this.props.campos.categorias).slice(0, -2)} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                {this.props.arr_categorias.map((i, x) => (
                                    <Button color="primary" size="sm" outline={String(this.props.campos.categorias).includes(i.nome) ? false : true}
                                        onClick={() => {
                                            this.props.setarCategoria(i.nome)
                                        }}
                                        style={{ marginBottom: 15 }}
                                    >
                                        {i.nome}
                                    </Button>
                                ))}
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Delivery</Label>
                                <Input type="select" name="tipo_delivery" id="exampleSelect" value={this.props.campos.tipo_delivery} onChange={this.props.handleChange}>
                                    <option value="Proprio">Proprio</option>
                                    <option value="Aplicativo">Aplicativo</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Franquia reponsável *</Label>
                                <Input type="select" name="franquia_rep" id="exampleSelect" value={this.props.campos.franquia_rep} onChange={this.props.handleChange}>
                                    <option value={-1}></option>
                                    {this.props.unidades.map(i => (
                                        <option value={i.id}>{i.nome}</option>
                                    ))}
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Status</Label>
                                <Input type="select" name="status" id="exampleSelect" value={this.props.campos.status} onChange={this.props.handleChange}>
                                    <option value="Inativo">Inativo</option>
                                    <option value="Ativo">Ativo</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Em promoção?</Label>
                                <Input type="select" name="em_promo" id="em_promo" value={this.props.campos.em_promo} onChange={this.props.handleChange}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label>Desconto da promoção</Label>
                                <Input type="text" disabled={this.state.isLoading} name="valor_desc"
                                    value={this.props.campos.valor_desc} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Entrega grátis?</Label>
                                <Input type="select" name="entrega_free" id="entrega_free" value={this.props.campos.entrega_free} onChange={this.props.handleChange}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Input>
                            </FormGroup>

                            <FormGroup>
                                <Label>Percentual de entrega</Label>
                                <Input type="text" disabled={this.state.isLoading} name="percet_entrega"
                                    value={this.props.campos.percet_entrega} onChange={this.props.handleChange} />
                            </FormGroup>

                            <FormGroup>
                                <Label for="exampleSelect">Redução espercial de entrega?</Label>
                                <Input type="select" name="reduz_entregua" id="reduz_entregua" value={this.props.campos.reduz_entregua} onChange={this.props.handleChange}>
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Input>
                            </FormGroup>

                        </Form>
                    </ModalBody>
                    <div style={{ marginLeft: 25, marginRight: 25 }}>
                        {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
                    </div>
                    <ModalFooter>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                {this.state.isLoading ? <p>Carregando... </p> : null}
                            </div>
                            <div>
                                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                                    Salvar
                                </Button>

                                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                                    () => {
                                        this.setState({ isErro: false })
                                        this.props.Cancel()
                                    }
                                }>
                                    Cancelar
                </Button>
                            </div>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}