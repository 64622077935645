import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row
} from "reactstrap";
import md5 from 'js-md5'
import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import CadUnidade from './CadCategorias';
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
// import { Container } from './styles';

export default class Categorias extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    Cadastro: false,
  }

  campos = {
    id: '',
    nome: '',
    nome_img: '',
    ordem: 10000,
    selectedFiles: null,
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get('categorias');
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectFile = event => {

    if (event.target.files[0]) {
      const horas = new Date();
      console.log(`Categorias${localStorage.getItem('@loja_app/loja')}-${horas.getDate()}-${horas.getMonth()}-${horas.getFullYear()}-${horas.getHours()}-${horas.getMinutes()}-${horas.getSeconds()}-${horas.getMilliseconds()}.jpeg`);
      this.setState({
        selectedFiles: event.target.files[0],
        nome_img: `Categorias${localStorage.getItem('@loja_app/loja')}-${horas.getDate()}-${horas.getMonth()}-${horas.getFullYear()}-${horas.getHours()}-${horas.getMinutes()}-${horas.getSeconds()}-${horas.getMilliseconds()}.jpeg`
      });
      console.log('cxzcz', md5(event.target.files[0].name))
    } else {
      this.setState({
        selectedFiles: null,
        nome_img: ''
      });
    }
  }

  render() {

    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <CadUnidade
            isVisible={this.state.Cadastro}
            Toggle={() => {
              this.setState({ Cadastro: !this.state.Cadastro });
              this.getServidor()
            }}
            handleChange={this.handleChange}
            selectFile={this.selectFile}
            campos={this.state}
            atualizaLista={this.getServidor}
          />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* Onde vai ficar o botão */}
                  <Button
                    size="sm"
                    onClick={async () => {
                      if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
                        this.setState({
                          ...this.state,
                          ...this.campos,
                          Cadastro: true,
                        })
                      } else {
                        alert('Usuário sem acesso!')
                      }
                    }}
                  >
                    <i class="fas fa-plus"></i>
                    <text>Cadastrar</text>
                  </Button>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4">
                  <nav aria-label="...">
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    return (
      <Table className="align-items-center table-flush" hover size="sm">
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nome</th>
            <th scope="col" />
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map(i => (
            <tr>
              <th scope="row"> {i.id} </th>
              <td scope="row">
                <Row style={{ alignItems: 'center' }}>
                  <img src={urlIMG.getHostIMG + "/" + i.nome_img} style={{ width: 50, height: 50, marginRight: 15 }} />
                  {i.nome}
                </Row>
              </td>
              <td scope="row" />
              <td scope="row">
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.setState({
                    ...this.state,
                    ...i,
                    Cadastro: true,
                  })
                }}>Editar</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}
