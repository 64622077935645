import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row
} from "reactstrap";
import GoogleMap from 'google-map-react';
import mapStyle from './style'
import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import md5 from 'js-md5'
import io from 'socket.io-client';
// import { Container } from './styles';

const API_KEY = 'AIzaSyCFOW4NYmjJwSBbkkcbvB2tg4k0ZGkFTFk'

const mapStyles = {
  width: '100%',
  height: '100hv'
}

const markerStyle = {
  height: '50px',
  width: '50px',
}

const imgStyle = {
  height: '100%'
}

export default class Mapa extends Component {

  state = {
    isLoading: true,
    isLoading2: false,
    isErro: false,
    dados: [],
    dados2: [],
    Cadastro: false,
    unidade: [],
    location: {
      latitude: 0,
      longitude: 0
    },
    arr_categorias: []
  }

  async componentDidMount() {
    //Graças te dou ó meu senho, louvado seja o nome do senhor
    await navigator.geolocation.getCurrentPosition(ll => {
      console.log('cxzcz ', ll.coords.latitude);
      this.setState({
        location: {
          latitude: ll.coords.latitude,
          longitude: ll.coords.longitude
        }
      })
    },
      error => console.log("Erro: ", error));
    console.log("xzcxzc", this.state.location.latitude)
    this.registerToSoket();
    this.getServidor();
  }

  registerToSoket = () => {
    const url = new VariaveisGlobais();
    const socket = io(url.getHost);
    console.log("222222")

    socket.on(`LocationMotoBoy1`, async newDados => {
      console.log("Peguei1!!!", newDados)

      this.setState({
        dados: this.state.dados.map(dados => (
          dados.id == newDados.id ? { ...dados, lastlt: newDados.lastlt, lastlg: newDados.lastlg } : dados
        ))
      })

    })

    socket.on(`LocationoffMotoBoy1`, async newDados => {
      console.log("Peguei2212!!!", newDados)

      this.setState({
        dados: this.state.dados.map(dados => (
          dados.id == newDados.id ? { ...dados, iswork: newDados.iswork } : dados
        ))
      })

    })

    socket.on(`LocationonMotoBoy1`, async newDados => {
      console.log("Peguei2ss3!!!", newDados[0])
      var temp = this.state.dados
      temp.push(newDados[0])
      this.setState({
        dados: temp
      })

    })
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {

      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get('motoboy/location');
      const response2 = await api.get('motoboy/location2');

      console.log("resposta22fsdd2 ", response2.data.data)
      if (response.status === 200) {
        await this.setState({
          dados: response.data.data,
          dados2: response2.data.data,
        });

        console.log('state', this.state.dados)

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  async getServidor2() {
    this.setState({ isErro: false, isLoading2: true });
    try {

      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get('motoboy/location');
      const response2 = await api.get('motoboy/location2');

      console.log("resposta22fsdd2 ", response2.data.data)
      if (response.status === 200) {
        await this.setState({
          dados: response.data.data,
          dados2: response2.data.data,
        });

        console.log('state', this.state.dados)

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading2: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  Marker = ({ obj }) => (
    <div style={{ backgroundColor: 'red' }} onClick={() => {
      alert(`${obj.nome_completo} (${obj.telefone}), ${obj.Pedidos} pedido(s) pendentes, e  ${obj.Entregas} entregas`)
    }}>
      {/* <img src={require("./../../../assets/img/point3.png")} alt={title} /> */}
      <i class="fas fa-motorcycle" style={{ color: obj.Pedidos + obj.Entregas > 0 ? "red" : "#3a3eed", fontSize: 25 }} />
    </div>
  );
  Marker3 = ({ obj }) => (
    <div style={{ backgroundColor: 'red' }} onClick={() => {
      alert(`${obj.nome_completo} (${obj.telefone}), ${obj.Pedidos} pedido(s) pendentes, e  ${obj.Entregas} entregas`)
    }}>
      {/* <img src={require("./../../../assets/img/point3.png")} alt={title} /> */}
      <i class="fas fa-bicycle" style={{ color: obj.Pedidos + obj.Entregas > 0 ? "red" : "#5b5da2", fontSize: 25 }} />
    </div>
  );
  //<i class="fas fa-store"></i>
  Marker2 = ({ obj }) => (
    <div style={{ backgroundColor: 'red' }} onClick={() => {
      alert(`${obj.nome}`)
    }}>
      {/* <img src={require("./../../../assets/img/point3.png")} alt={title} /> */}
      <i class="fas fa-store" style={{ color: "green", fontSize: 16 }} />
    </div>
  );

  render() {
    return (
      <div>
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* Onde vai ficar o botão */}

                </CardHeader>

                {this.Corpo()}

                <CardFooter className="py-4">
                  <Button
                    size="sm"
                    onClick={async () => {
                      this.getServidor2()
                    }}
                  >
                    <i class="fas fa-redo-alt"></i>
                    <text>{this.state.isLoading2 ? "Carregando" : "Atualizar"}</text>
                  </Button>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>

      </div >
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  // setGoogleMapRef = ({ map, maps }) => {
  //   this.googleMapRef = map
  //   this.googleRef = maps
  //   var temp = []

  //   for (var i = 0; i < this.state.dados.length; i++) {
  //     temp.add({lat: this.state.dados[i].lastlt, lng: this.state.dados[i].lastlg})
  //   }

  //   let locations = temp
  //   let markers = locations && locations.map((location) => {
  //     return new this.googleRef.Marker({position: location})
  //   })

  //   let markerCluster = new MarkerClusterer(map, markers, {
  //     imagePath: "https://i.pinimg.com/564x/72/30/36/723036ba0e241c1eb9513689ddbd2dd9.jpg",
  //     gridSize: 10,
  //     minimumClusterSize: 2
  //   })
  // }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    // const Map = withScriptjs(withGoogleMap((props) => (
    //   <GoogleMap
    //     style={mapStyle.mapStyle}
    //     defaultZoom={15}
    //     onGoogleApiLoaded={this.setGoogleMapRef}
    //     defaultCenter={{ lat: this.state.location.latitude, lng: this.state.location.longitude }}
    //   >

    //   </GoogleMap>
    // )));
    return (
      <div style={{ width: '100%' }}>

        <GoogleMap
          style={mapStyles}
          bootstrapURLKeys={{ key: API_KEY }}
          center={{ lat: this.state.location.latitude, lng: this.state.location.longitude }}
          zoom={15}
        >
          {this.state.dados.map((i, key) =>
          (
            i.iswork === 1 && i.tipo_veiculo.toUpperCase() !== 'BIKE' ?
              <this.Marker
                key={key}
                title={`Teste`}
                lat={i.lastlt}
                lng={i.lastlg}
                obj={i}
              >
              </this.Marker> : null
          )
          )}

          {this.state.dados.map((i, key) =>
          (
            i.iswork === 1 && i.tipo_veiculo.toUpperCase() === 'BIKE' ?
              <this.Marker3
                key={key}
                title={`Teste`}
                lat={i.lastlt}
                lng={i.lastlg}
                obj={i}
              >
              </this.Marker3> : null
          )
          )}

          {this.state.dados2.map((i, key) =>
          (
            i.is_aberto === "Aberto" ?
              <this.Marker2
                key={key}
                title={`Teste`}
                lat={i.latitude}
                lng={i.longitude}
                obj={i}
              >
              </this.Marker2> : null
          )
          )}
        </GoogleMap>
        {/* <Map
          googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`}
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `800px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        >
          <Marker
            position={{ lat: -7.535396867989507, lng: -46.04510986793986 }}
          />
        </Map> */}
        <div style={{ height: 180, marginTop: 180 }}>
          <i>{this.state.dados[0].lastlg}</i>
        </div>
      </div>
    )
  }

  Status(isativo) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: isativo ? '#D4EDDA' : '#F8D7DA',
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }

}


function Point(props) {
  return (
    <div style={{ width: 25, height: 25, backgroundColor: 'red' }}>
      <i className="fas fa-motorcycle" style={{ fontSize: 25 }} />
      <div>ok</div>
    </div>
  )
}

//<i class="fas fa-motorcycle"></i>