import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, CustomInput
} from 'reactstrap';
import api from "./../../../utlites/api";
// import { Container } from './styles';

export default class SCadProduto extends Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: ''
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);
      data.append('nome', this.props.campos.nome);
      data.append('preco', String(this.props.campos.preco).replace(",", "."));
      data.append('obs', this.props.campos.obs);
      data.append('id_grupo', this.props.campos.id_grupo);
      data.append('tipo_produto', 'Montagem');
      data.append('imagem_nome', this.props.campos.imagem_nome);
      data.append('posicao', this.props.campos.posicao);
      data.append('isdisp', this.props.campos.isdisp);
      data.append('id_loja', localStorage.getItem('@loja_app/loja'));
      if (this.props.campos.selectedFiles !== null && this.props.campos.selectedFiles !== undefined) {
        data.append('image', this.props.campos.selectedFiles);
      }

      var response;
      if (this.props.campos.id === '') {
        response = await api.put('produto', data);
      } else {
        response = await api.post('produto', data);
      }

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleExcluir = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);

      var response = await api.delete(`produto/${this.props.campos.id}`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Cadastro de grupo de produto</h2></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Id</Label>
                <Input type="text" disabled={true} name="nome" value={this.props.campos.id} />
              </FormGroup>

              <FormGroup>
                <Label>Nome (*)</Label>
                <Input type="text" disabled={this.state.isLoading} name="nome" value={this.props.campos.nome} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Observação</Label>
                <Input type="text" disabled={this.state.isLoading} name="obs" value={this.props.campos.obs} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Preço</Label>
                <Input type="text" disabled={this.state.isLoading} name="preco" value={String(this.props.campos.preco).replace(".", ",")} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Posição</Label>
                <Input type="text" disabled={this.state.isLoading} name="posicao" value={this.props.campos.posicao} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Grupo (*)</Label>
                <Input type="select" disabled={this.state.isLoading} name="id_grupo" id="exampleSelect" value={this.props.campos.id_grupo} onChange={this.props.handleChange}>
                  <option value="-1"></option>
                  {this.props.grupos.map(i => (
                    <option value={i.id}>{i.nome}</option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Status</Label>
                <Input type="select" name="isdisp" id="exampleSelect" value={this.props.campos.isdisp} onChange={this.props.handleChange} disabled={this.state.isLoading}>
                  <option value="Inativo">Inativo</option>
                  <option value="Ativo">Ativo</option>
                </Input>
              </FormGroup>

            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                {this.props.campos.id !== "" ?
                  <Button disabled={this.state.isLoading} color="danger" size="sm" onClick={this.handleExcluir}>
                    Excluir
                  </Button> : null
                }
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Toggle()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
