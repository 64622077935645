import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row, Form, FormGroup, Label, Input
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";


export default class Send_Push extends Component {

  state = {
    isLoading: false,
    isErro: false,

    titulo: '',
    msg: '',
    isSucess: false
  }

  componentDidMount() {
    if(localStorage.getItem('@admin_app/usernameTipo') === 'Admin'){
      
    } else {
      this.setState({
        isLoading: true
      })
    }
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

 handleSend = async e => {
    var resultado = window.confirm(`Deseja mesmo enviar essa notificação a todos os motoboys? lembre-se de verificar a mensagem e eventuais erros ortograficos`);
    if (resultado) {
      this.setState({ isLoading: true });
      try {
        const data = new FormData();

        data.append('Titulo', this.state.titulo);
        data.append('Msg', this.state.msg);
        data.append('Dest', 'All');

        const response = await api.post(`/admin/sendPush2`, data);
        console.log("resposta ", response.data)
        if (response.status === 200) {
          this.setState({isSucess: true})
        } else {
          this.setState({ isLoading: false });
          alert("Ocorreu um erro na requisição")
        }

      } catch (error) {
        this.setState({ isLoading: false });
          alert("Ocorreu um erro na requisição")
      }
    }
  }

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <Row >
            <div className="col" >
              <Card className="shadow">
                <CardHeader className="border-0" >
                  {/* Onde vai ficar o botão */}
                  <h4>Corpo da mensagem para os motoboys</h4>
                </CardHeader>

                <Form style={{ marginLeft: 25, marginRight: 35 }}>
                  <FormGroup>
                    <Label>Titulo</Label>
                    <Input type="text" disabled={this.state.isLoading} size="sm" name="titulo" value={this.state.titulo} onChange={this.handleChange} />
                  </FormGroup>

                  <FormGroup>
                    <Label>Mensagem</Label>
                    <Input type="text" disabled={this.state.isLoading} size="sm" name="msg" value={this.state.msg} onChange={this.handleChange} />
                  </FormGroup>

                </Form>


                <CardFooter className="py-4 border-0">
                  <nav aria-label="...">
                    <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleSend}>
                      {this.state.isSucess ? "Notificação enviada" : "Enviar"}
                    </Button>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

}