import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import SCadProduto from './SCadProduto';
import mascara from './../../../utlites/mascaraValor'
import md5 from 'js-md5'
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
// import { Container } from './styles';

export default class Sproduto extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    Cadastro: false,
    grupos: []
  }

  campos = {
    id: '',
    nome: '',
    preco: 0,
    obs: '',
    id_grupo: '',
    tipo_produto: '',
    imagem_nome: '',
    posicao: '',
    isdisp: 'Ativo',
    id_loja: '',
    selectedFiles: null
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get(`sproduto/${localStorage.getItem('@loja_app/loja')}/${this.props.location.input.id}`);
      const resp_grupos = await api.get(`sgrupo_prod/${localStorage.getItem('@loja_app/loja')}`);
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data,
          grupos: resp_grupos.data.data.filter( i => (i.id === this.props.location.input.id))
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !e.target.value });
  };

  selectFile = event => {

    if (event.target.files[0]) {
      this.setState({
        selectedFiles: event.target.files[0],
        imagem_nome: md5(event.target.files[0].name) + '.jpeg'
      });
      console.log('cxzcz', md5(event.target.files[0].name))
    } else {
      this.setState({
        selectedFiles: null,
        imagem_nome: ''
      });
    }
  }

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <SCadProduto
            isVisible={this.state.Cadastro}
            Toggle={() => {
              this.setState({ Cadastro: !this.state.Cadastro });
              this.getServidor()
            }}
            handleChange={this.handleChange}
            handleCheck={this.handleCheck}
            campos={this.state}
            atualizaLista={this.getServidor}
            grupos={this.state.grupos}
            selectFile={this.selectFile}
          />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* Onde vai ficar o botão */}
                  <Button
                    size="sm"
                    onClick={async () => {
                      this.setState({
                        ...this.state,
                        ...this.campos,
                        Cadastro: true,
                      })
                    }}
                  >
                    <i class="fas fa-plus"></i>
                    <text>Cadastrar</text>
                  </Button>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Row style={{ alignItems: 'center', marginLeft: 1, fontSize: 12 }}>
                      {this.Status(false)}
                      Inativo
                      <div style={{ width: 25 }} />
                      {this.Status(true)}
                      Ativo
                    </Row>
                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    return (
      <Table className="align-items-center table-flush" hover responsive>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Nome</th>
            <th scope="col">Preço</th>
            <th scope="col">Grupo</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map(i => (
            <tr>
              <th scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {this.Status(i.isdisp === 'Ativo' ? true : false)} {i.id}
                </Row>
              </th>
              <td scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {i.imagem_nome === '' ?
                  null :
                  <img src={urlIMG.getHostIMG + "/assets/" + i.imagem_nome} style={{ width: 50, height: 50, marginRight: 15 }} />}
                  {i.nome}
                </Row>
              </td>
              <td scope="row">R$ {mascara(eval(parseFloat(i.preco)).toFixed(2))}</td>
              <td scope="row">{i.nome_grupo}</td>
              <td scope="row">
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.setState({
                    ...this.state,
                    ...i,
                    Cadastro: true,
                  })
                }}>Editar</Button>
                
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  Status(isativo) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: isativo ? '#D4EDDA' : '#F8D7DA',
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }
}
