import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import mascara from './../../../utlites/mascaraValor'
import md5 from 'js-md5'
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import moment from 'moment-timezone';
import LancCarteira from './LancCarteira'

// import { Container } from './styles';

export default class Motoboys_Carteira extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    Cadastro: false,
    isLoading2: false,
    saldo: 0,
    saldoAnt: 0
  }

  campos = {
    id: '',
    descricao: '',
    data: moment.tz('Africa/Abidjan').format("DD/MM/YYYY"),
    pedido: '',
    valor: '',
    id_motoboy: '',
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      await this.setState({ id_motoboy: this.props.location.input.id })
      const data = new FormData();
      var currentDate = moment.tz('Africa/Abidjan').subtract(1440, 'hours').format("YYYY-MM-DD HH:mm:ss");

      data.append('idmotoboy', this.props.location.input.id);
      data.append('data', currentDate);

      const response = await api.post(`admin/motoboy/carteira`, data);
      const saldo = await api.post(`admin/motoboy/saldoCart`, data);
      const saldoAnt = await api.post(`admin/motoboy/saldoAnt`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data,
          saldo: saldo.data.saldo,
          saldoAnt: saldoAnt.data.saldo
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !e.target.value });
  };

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <LancCarteira
            isVisible={this.state.Cadastro}
            Toggle={() => {
              this.setState({ Cadastro: !this.state.Cadastro });
              this.getServidor()
            }}
            handleChange={this.handleChange}
            handleCheck={this.handleCheck}
            campos={this.state}
            idmotoboy={this.props.location.input.id}
            atualizaLista={this.getServidor}
          />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* Onde vai ficar o botão */}
                  <Button
                    size="sm"
                    onClick={async () => {
                      if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
                        this.setState({
                          ...this.state,
                          ...this.campos,
                          Cadastro: true,
                        })
                      } else {
                        alert('Usuário sem acesso!')
                      }
                    }}
                  >
                    <i class="fas fa-plus"></i>
                    <text>Realizar lançamento</text>
                  </Button>
                  <h3>
                    Saldo de {this.props.location.input.nome}: {this.state.saldo >= 0 ? "" : "-"} R$ {mascara(eval(parseFloat(this.state.saldo)).toFixed(2))}
                  </h3>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4">

                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    return (
      <div>
        <Table className="align-items-center table-flush" hover responsive>
          <thead>
            <tr>
              <th scope="col">Saldo anterior (Ultimos 60 dias) de {this.props.location.input.nome}</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">{this.state.saldoAnt >= 0 ? "" : "-"} R$ {mascara(eval(parseFloat(this.state.saldoAnt)).toFixed(2))}</th>
            </tr>
          </thead>
        </Table>
        <Table className="align-items-center table-flush" hover responsive>
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Descrição</th>
              <th scope="col">Data</th>
              <th scope="col">Valor</th>
              <th scope="col">Ação</th>
            </tr>
          </thead>
          <tbody>
            {this.state.dados.map(i => (
              <tr>
                <th scope="row" >
                  <Row style={{ alignItems: 'center', marginLeft: 5 }}>
                    {i.id}
                  </Row>
                </th>
                <td scope="row">
                  <Row style={{ alignItems: 'center' }}>
                    {i.descricao}
                  </Row>
                </td>
                <td scope="row">{moment.tz(i.data, moment.tz.guess()).format("DD/MM/YY")}</td>
                <td scope="row">{i.valor >= 0 ? "" : "-"} R$ {mascara(eval(parseFloat(i.valor)).toFixed(2))}</td>
                <td scope="row">
                  <Button outline color="warning" size="sm" onClick={async () => {
                    if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
                      var resultado = window.confirm(`Deseja mesmo excluir esse registro?`);
                      if (resultado) {
                        this.setState({ isLoading2: true });
                        try {
                          const data = new FormData();

                          const response = await api.delete(`admin/motoboy/delCart/${i.id}`);
                          console.log("resposta ", response.data)
                          if (response.status === 200) {
                            this.setState({ isLoading2: false });
                            this.getServidor()
                          } else {
                            this.setState({ isLoading2: false });
                            alert("Ocorreu um erro na requisição")
                          }

                        } catch (error) {
                          this.setState({ isLoading2: false });
                          alert("Ocorreu um erro na requisição")
                        }
                        this.setState({ isLoading2: false });
                      }
                    } else {
                      alert('Usuário sem acesso!')
                    }
                  }}>Excluir</Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  Status(isativo) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: isativo ? '#D4EDDA' : '#F8D7DA',
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }
}
