const serve    = process.env.REACT_APP_HOST;
const serveIMG = process.env.REACT_APP_HOST_IMG;


export default  class VariaveisGlobais {
    get getHost(){
        return serve;
    }

    get getHostIMG(){
        return serveIMG;
    }

}