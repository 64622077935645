import GruposProd   from './layouts/lojas/gruposProd/gruposProd'
import SgruposProd  from './layouts/lojas/sgruposProd/SgruposProd'
import Produto      from './layouts/lojas/Produtos/Produto'
import SProduto     from './layouts/lojas/SProdutos/SProduto'
import CadUser      from './layouts/lojas/usuarios/User'
import Monitor      from './layouts/lojas/pedidos/monitor_pedidos' 
import Carteira     from './layouts/lojas/carteira/Carteira'
import Extrato      from './layouts/lojas/extrato/extrato_pedidos'

var routes_loja_cadastro = [
  {
    path: "/monitor",
    name: "Monitor de pedidos",
    label: "Monitor de pedidos",
    icon: "fas fa-shopping-basket",
    type: 'menu',
    component: Monitor,
    layout: "/loja"
  },
  {
    type: 'separat',
  },
  {
    path: "/vendas",
    name: "Vendas",
    label: "Historico de vendas",
    icon: "fas fa-receipt",
    type: 'menu',
    component: Extrato,
    layout: "/loja"
  },
  {
    path: "/gruposprod",
    name: "Cardapio",
    label: "Configuração de cardapio",
    icon: "far fa-object-group",
    type: 'menu',
    component: GruposProd,
    layout: "/loja"
  },
  {
    path: "/sgruposprod",
    name: "Montagem",
    label: "Cadastro grupos de montagem",
    icon: "fas fa-stream",
    type: 'menu',
    component: SgruposProd,
    layout: "/loja"
  },
  {
    type: 'separat',
  },
  {
    path: "/carteira",
    name: "Carteira",
    label: "Minha carteira",
    icon: "fas fa-wallet",
    type: 'menu',
    component: Carteira,
    layout: "/loja"
  },
  {
    path: "/usuario",
    name: "Usuários",
    label: "Cadastro de usuário",
    icon: "fas fa-users-cog",
    type: 'menu',
    component: CadUser,
    layout: "/loja"
  },
  
];

export default routes_loja_cadastro;
//<i class="fas fa-shopping-basket"></i>