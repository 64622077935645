import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import mascara from './../../../utlites/mascaraValor'
import md5 from 'js-md5'
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import moment from 'moment-timezone';

// import { Container } from './styles';

export default class Motoboys_Carteira extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    Cadastro: false,
    isLoading2: false,
    saldo: 0,
    saldoAnt: 0
  }

  campos = {
    id: '',
    descricao: '',
    data: moment.tz('Africa/Abidjan').format("DD/MM/YYYY"),
    pedido: '',
    valor: '',
  }

  componentDidMount() {
    this.getServidor()
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      var currentDate = moment.tz('Africa/Abidjan').subtract(1440, 'hours').format("YYYY-MM-DD HH:mm:ss");

      data.append('idloja', localStorage.getItem('@loja_app/loja'));
      data.append('data', currentDate);

      const response = await api.post(`lojas/carteira`, data);
      const saldo = await api.post(`lojas/saldoCart`, data);
      const saldoAnt = await api.post(`lojas/saldoAnt`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data,
          saldo: saldo.data.saldo,
          saldoAnt: saldoAnt.data.saldo
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !e.target.value });
  };

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0" style={{ display: 'flex', justifyContent: 'space-between' }}>
                  {/* Onde vai ficar o botão */}

                  <h3>
                    Meu saldo: {this.state.saldo >= 0 ? "" : "-"} R$ {mascara(eval(parseFloat(this.state.saldo)).toFixed(2))}
                  </h3>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4">

                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    return (
      <div>
        <Table className="align-items-center table-flush" hover responsive>
          <thead>
            <tr>
              <th scope="col">Saldo anterior (Ultimos 60 dias)</th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col"></th>
              <th scope="col">{this.state.saldoAnt >= 0 ? "" : "-"} R$ {mascara(eval(parseFloat(this.state.saldoAnt)).toFixed(2))}</th>
            </tr>
          </thead>
        </Table>
        <Table className="align-items-center table-flush" hover responsive>
          <thead>
            <tr>
              <th scope="col">Id</th>
              <th scope="col">Descrição</th>
              <th scope="col">Data</th>
              <th scope="col">Valor</th>
            </tr>
          </thead>
          <tbody>
            {this.state.dados.map(i => (
              <tr>
                <th scope="row" >
                  <Row style={{ alignItems: 'center', marginLeft: 5 }}>
                    {i.id}
                  </Row>
                </th>
                <td scope="row">
                  <Row style={{ alignItems: 'center' }}>
                    {i.pedido > 0 ? i.pedido + ' - ' : ''} {i.descricao}
                  </Row>
                </td>
                <td scope="row">{moment.tz(i.data, moment.tz.guess()).format("DD/MM/YY")}</td>
                <td scope="row">{i.valor >= 0 ? "" : "-"} R$ {mascara(eval(parseFloat(i.valor)).toFixed(2))}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  Status(isativo) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: isativo ? '#D4EDDA' : '#F8D7DA',
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }
}
