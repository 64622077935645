import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Navbar,
  Nav,
  Container,
  Media
} from "reactstrap";
import InfUser_lojas from './../InfUser/InfUser_lojas'

class LojasNavbar extends React.Component {
  render() {
    return (
      <div>
        <Navbar className="navbar-top " expand="md" id="navbar-main">
          <Container fluid>
            <h2
              className="h4 mb-0 text-black text-uppercase d-none d-lg-inline-block"              
            >
              {this.props.brandText}
            </h2>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <InfUser_lojas {... this.props}/>
            </Nav>
          </Container>
        </Navbar>
      </div>
    );
  }
}

export default LojasNavbar;
