import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row, UncontrolledTooltip
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import mascara, { diffDate } from './../../../utlites/mascaraValor'
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import moment from 'moment-timezone'
import io from 'socket.io-client';
import MetaEntrega from './meta_entrega'
import ViewPedido from './view_pedido'
import StatusPedido from './status_pedido'
import sound from './alert.mp3'


export default class Pedidos_Monitor extends Component {

  state = {
    isLoading: true,
    isErro: false,
    dados: [],
    meta: false,
    grupos: [],
    timeNow: '',
    cont: 1,
    isLoading2: false,
    viewPedido: false,
    altStatus: false,
    itens: [],
    tipo_delivery: 'nada'
  }

  campos = {
  }

  audio = new Audio(sound);

  async componentDidMount() {
    this.registerToSoket();
    this.getServidor();
    this.setState({
      timeNow: moment.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'),
    });
    setInterval(() => {
      this.setState({
        timeNow: moment.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'),
      });
      console.log(this.state.timeNow)
    }, 60000);

    this.audio = new Audio(sound);
    this.audio.load()
  }

  componentWillUnmount() {
    //this.audio.pause();
  }

  registerToSoket = () => {
    const url = new VariaveisGlobais();
    const socket = io(`${url.getHost}/`);

    socket.on(`NewPedido_#${localStorage.getItem('@loja_app/loja')}`, async newDados => {
      await this.setState({ dados: [newDados, ...this.state.dados] });
      await this.setState({
        timeNow: moment.tz(moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'),
      });

      this.audio = new Audio(require('./../../../assets/alert.mp3'));
      await this.audio.load()
      await this.audio.play();
      console.log('Chegou: ', newDados)
    })

    socket.on(`StopSoundPedido_#${localStorage.getItem('@loja_app/loja')}`, async newDados => {
      console.log('Parou: ', newDados.id)
      await this.setState({
        dados: this.state.dados.map(dados => (
          dados.id == newDados.id ? { ...dados, isvist: 1, ok: true } : dados
        ))
      })
      this.audio.pause();
      console.log('Parou: ', this.state)
    })

    socket.on(`CancelPedido_#${localStorage.getItem('@loja_app/loja')}`, async newDados => {
      console.log('Parou: ', newDados.id)
      await this.setState({
        dados: this.state.dados.filter(dados => (
          dados.id != newDados.id
        ))
      })
      this.audio.pause();
      console.log('Parou: ', this.state)
    })

    socket.on(`MotoBoy1_#`, async newDados => {
      console.log('Parou: ', newDados.id)
      await this.setState({
        dados: this.state.dados.map(dados => (
          dados.id == newDados.id ? { ...dados, id_motoboy: newDados.id_motoboy, nome_moto: newDados.nome_moto, telefone_moto: newDados.telefone_moto } : dados
        ))
      })
      //this.audio.pause();
    })

    socket.on(`UpdatePedido_#${localStorage.getItem('@loja_app/loja')}`, async newDados => {
      console.log('Parou: ', newDados.id)
      await this.setState({
        dados: this.state.dados.map(dados => (
          dados.id == newDados.id ? { ...dados, status: newDados.status } : dados
        ))
      })

      await this.setState({
        dados: this.state.dados.filter(dados => (
          dados.status != 'Pedido entregue'
        ))
      })

      await this.setState({
        dados: this.state.dados.filter(dados => (
          dados.status != 'Pedido cancelado'
        ))
      })
    })

  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get(`pedidos/${localStorage.getItem('@loja_app/loja')}`);
      const inf = await api.get(`myloja/${localStorage.getItem('@loja_app/loja')}`);
      console.log("resposta ", inf.data.data)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data,
          tipo_delivery: inf.data.data[0].tipo_delivery
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  async handleCheck2(i) {
    this.audio.pause();
    this.setState({ ... this.state, ...i, meta: true, });
  }

  async handleView(i) {
    this.setState({ isErro: false, isLoading2: true });
    try {
      const response = await api.get(`getItensLoja/${i}`);
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        this.setState({
          itens: response.data.data,
          viewPedido: true
        });

      } else {
        alert("Erro na comunicação com o servidor")
      }

    } catch (error) {
      alert("Erro na comunicação com o servidor")
    }
    this.setState({ isLoading2: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleCheck = e => {
    this.setState({ [e.target.name]: !e.target.value });
  };

  render() {
    return (
      <div  >
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <MetaEntrega
            isVisible={this.state.meta}
            Toggle={() => {
              this.setState({ meta: !this.state.meta });
            }}
            Toggle2={async () => {
              this.setState({ meta: !this.state.meta });
              try {
                const response = await api.get(`getItensLoja/${this.state.id}`);
                console.log("resposta ", response.data.data)
                if (response.status === 200) {
                  await this.setState({
                    itens: response.data.data,
                    viewPedido: true
                  });

                  var conteudo = document.getElementById('formImpPedido').innerHTML,
                    tela_impressao = window.open('about:blank');

                  tela_impressao.document.write(conteudo);
                  tela_impressao.window.print();
                  tela_impressao.window.close();

                  await this.setState({
                    viewPedido: false
                  });


                } else {
                  alert("Erro na comunicação com o servidor")
                }

              } catch (error) {
                console.log("Erro: ", error)
                alert("Erro na comunicação com o servidor")
              }

            }}
            handleChange={this.handleChange}
            campos={this.state}
          />
          <ViewPedido
            isVisible={this.state.viewPedido}
            Toggle={() => {
              this.setState({ viewPedido: !this.state.viewPedido });
            }}
            check={() => {
              this.audio.pause();
              this.setState({ ... this.state, meta: true, viewPedido: false });
            }}
            handleChange={this.handleChange}
            campos={this.state}
          />
          <StatusPedido
            isVisible={this.state.altStatus}
            Toggle={() => {
              this.setState({ altStatus: !this.state.altStatus });
            }}
            campos={this.state}
          />
          <Row >
            <div className="col" >
              <Card className="shadow">
                <CardHeader className="border-0" >
                  {/* Onde vai ficar o botão */}
                  <Row style={{ alignItems: 'center', marginLeft: 1, fontSize: 12 }}>
                    {this.Legenda('#dce400')}
                    Em andamento
                    <div style={{ width: 25 }} />
                    {this.Legenda('#2683f5')}
                    Novo
                    <div style={{ width: 25 }} />
                    {this.Legenda('#00e436')}
                    Saiu para entrega
                  </Row>
                </CardHeader>


                {this.Corpo()}

                <CardFooter className="py-4 border-0">
                  <nav aria-label="...">

                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>

        </Container>
      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    return (
      <Table className="align-items-center table-flush" hover responsive>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Cliente</th>
            <th scope="col"></th>
            <th scope="col">Espera</th>
            <th scope="col">Valor</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map((i, key) => (
            <tr key={key}>
              <th scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {this.Status(i)} {i.id}
                </Row>
              </th>
              <td scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {i.nomeApp} ({i.telefoneApp})
                </Row>
              </td>
              <td scope="row">{i.id_motoboy !== null ?
                <a href="#" id={`Tooltip_${key}`}><i class="fas fa-motorcycle"></i>
                  <UncontrolledTooltip placement="right" target={`Tooltip_${key}`}>
                    {i.nome_moto} ({i.telefone_moto})
                  </UncontrolledTooltip>
                </a>
                : ""}
              </td>
              <td scope="row">{diffDate(i.data_pedido, this.state.timeNow)}</td>
              <td scope="row">R$ {this.state.tipo_delivery === 'Proprio' ? mascara(i.valor_pedido.toFixed(2)) : mascara(i.valor_pedido_f.toFixed(2))}</td>
              <td scope="row">
                {this.acoes(i)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  acoes(i) {
    if (i.isvist === 0) {
      return (
        <div>
          <Button outline color="success" size="sm" disabled={this.state.isLoading2}
            onClick={async () => {
              this.handleCheck2(i)
            }}>
            {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="fas fa-check" />}
          </Button>
          <Button outline color="info" size="sm" disabled={this.state.isLoading2}
            onClick={async () => {
              this.setState({ ... this.state, ...i });
              this.handleView(i.id)
            }}>
            {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="far fa-eye" />}
          </Button>
          <Button outline color="danger" size="sm" disabled={this.state.isLoading2}
            onClick={async () => {
              var resultado = window.confirm(`Deseja recusar o pedido de numero: ${i.id}?`);
              if (resultado) {
                this.setState({ isLoading2: true });
                try {
                  const data = new FormData();
                  data.append('id_user', this.state.idUser);

                  const response = await api.get(`cancelarPedido/${i.id}/${localStorage.getItem('@loja_app/loja')}`);
                  console.log("resposta ", response.data)
                  if (response.status === 200) {
                    this.setState({ isLoading2: false });
                  } else {
                    this.setState({ isLoading2: false });
                    alert("Ocorreu um erro na requisição")
                  }

                } catch (error) {
                  this.setState({ isLoading2: false });
                  alert("Ocorreu um erro na requisição")
                }
                this.setState({ isLoading2: false });
              }
            }}>
            {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="fas fa-times" />}
          </Button>
        </div>
      )
    } else {
      return (
        <div>
          <Button outline color="info" size="sm" disabled={this.state.isLoading2}
            onClick={async () => {
              this.setState({ ... this.state, ...i });
              this.handleView(i.id)
            }}>
            {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="far fa-eye" />}
          </Button>
          {this.state.tipo_delivery === 'Proprio' || i.id_endereco === null ?
            <Button outline color="primary" size="sm" disabled={this.state.isLoading2}
              onClick={async () => {
                this.setState({ ... this.state, ...i, altStatus: true, });
              }}>
              {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="fas fa-bars" />}
            </Button> : null}
        </div>
      )

    }
  }

  Legenda(color) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: color,
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }



  Status(value) {

    if (value.isvist === 0 && value.status === "Aguardando confirmação") {
      return (
        <div style={{
          width: 15,
          height: 15,
          backgroundColor: '#2683f5',
          borderRadius: 50,
          marginRight: 10
        }}>

        </div>
      )
    } else {
      if (value.status === "Saiu para entrega") {
        return (
          <div style={{
            width: 15,
            height: 15,
            backgroundColor: '#00e436',
            borderRadius: 50,
            marginRight: 10
          }}>

          </div>
        )
      } else {
        return (
          <div style={{
            width: 15,
            height: 15,
            backgroundColor: '#dce400',
            borderRadius: 50,
            marginRight: 10
          }}>

          </div>
        )
      }

    }


  }

}
