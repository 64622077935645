import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, CustomInput
} from 'reactstrap';
import api from "./../../../utlites/api";
// import { Container } from './styles';

export default class MetaEntrega extends Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    inf: '30 - 40 min'
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_pedido', this.props.campos.id);
      data.append('loja', localStorage.getItem('@loja_app/loja'));
      data.append('tempo_delivery', this.state.inf);
      data.append('token_app', this.props.campos.token_app);
     

      var response = await api.post('checkPedido', data);
      
      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.Toggle2();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Tempo pra retirada / entrega</h2></ModalHeader>
          <ModalBody>
            <Form style={{width: '100%'}}>
              <FormGroup style={{justifyContent: 'justifyContent', width: '100%'}}>
                <Button outline={this.state.inf === '10 - 20 min' ? false : true} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({inf: '10 - 20 min'})
                  }}>
                  10 - 20 min
                </Button>
                <Button outline={this.state.inf === '30 - 40 min' ? false : true} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({inf: '30 - 40 min'})
                  }}>
                  30 - 40 min
                </Button>
                <Button outline={this.state.inf === '50 - 60 min' ? false : true} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({inf: '50 - 60 min'})
                  }}>
                  50 - 60 min
                </Button>
                <Button outline={this.state.inf === '1:30 hr' ? false : true} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({inf: '1:30 hr'})
                  }}>
                  1:30 hr
                </Button>
              </FormGroup>
            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
