import moment from 'moment-timezone'

export default function mascara(valor) {
    valor = valor.toString().replace(/\D/g, "");
    valor = valor.toString().replace(/(\d)(\d{8})$/, "$1.$2");
    valor = valor.toString().replace(/(\d)(\d{5})$/, "$1.$2");
    valor = valor.toString().replace(/(\d)(\d{2})$/, "$1,$2");
    return valor
}


export function diffDate(ini, fim) {   

    var antes = new Date(moment.tz(ini, moment.tz.guess()).format('YYYY-MM-DD HH:mm:ss'));
    var depois = new Date(fim);

    var diffMilissegundos = depois - antes;

    console.log('Ini: ', antes)
    console.log('Fim: ', depois)
    console.log('Teste: ', diffMilissegundos)

    var diffSegundos = diffMilissegundos / 1000;
    var diffMinutos = diffSegundos / 60;
    var diffHoras = diffMinutos / 60;
    var diffDias = diffHoras / 24;
    var diffMeses = diffDias / 30;

    if(diffSegundos < 61) {        
        return `< 1 min`
    } else if (diffMinutos > 1 && diffMinutos < 61) {
        return `${diffMinutos.toFixed(0)} min`
    } else if (diffHoras > 0.99 && diffHoras < 24) {
        return `${diffHoras.toFixed(2)} hr(s)`
    } else {
        return `>  1 dias`
    }
    //return diffMilissegundos
}