
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col, Alert
} from "reactstrap";

import api from './../../../utlites/api'

class Login extends React.Component {

  state = {
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    username: '',
    senha: ''
  }

  handleLogar = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('username', this.state.username);
      data.append('senha', this.state.senha);

      const response = await api.post('loginLoja', data);


      console.log("resposta ", response.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          if (response.data.login) {
            localStorage.setItem('@loja_app/username', this.state.username);
            localStorage.setItem('@loja_app/tipo', response.data.tipo);
            localStorage.setItem('@loja_app/loja', response.data.loja);
            this.props.history.push('/loja')
          } else {
            this.setState({ isErro: true, erroMotivo: response.data.motivo });
          }
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>E-mail</small>
            </div>
            <Form role="form">
              <FormGroup className="mb-3">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-user"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="E-mail" type="text" name="username" value={this.state.username} onChange={this.handleChange} autoComplete="new-email" />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-lock-circle-open" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder="Senha" name="senha" value={this.state.senha} onChange={this.handleChange} type="password" autoComplete="new-password" />
                </InputGroup>
              </FormGroup>
              <div className="custom-control custom-control-alternative custom-checkbox">
                <input
                  className="custom-control-input"
                  id=" customCheckLogin"
                  type="checkbox"
                />
                <label
                  className="custom-control-label"
                  htmlFor=" customCheckLogin"
                >
                  <span className="text-muted">Lembre-me</span>
                </label>
              </div>
              <div className="text-center">
                <Button disabled={this.state.isLoading} onClick={this.handleLogar} className="my-4" type="button">
                  Logar
                </Button>
              </div>
              <div>
                {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
            </Form>
          </CardBody>
        </Card>
      </Col>
    );
  }
}

export default Login;
