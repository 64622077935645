import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, CustomInput
} from 'reactstrap';
import api from "./../../../utlites/api";
// import { Container } from './styles';

export default class StatusPedido extends Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    inf: ''
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      if (this.state.inf !== '') {
        const data = new FormData();
        data.append('id_pedido', this.props.campos.id);
        data.append('loja', this.props.campos.id_loja);
        data.append('token_app', this.props.campos.token_app);
        data.append('status', this.state.inf);


        var response = await api.post('admin/pedidos', data);

        console.log("resposta ", response.data.data)
        if (response.status === 200) {
          if (response.data.error) {
            console.log('Erro: ', response.data)
            this.setState({ isErro: true, erroMotivo: response.data.message });
          } else {
            this.props.Toggle();
          }

        } else {
          this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
        }
      } else { this.props.Toggle(); }
    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleTiraMoto = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {


      var response = await api.get(`/admin/tiraMotoboy/${this.props.campos.id}`);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  status(Input) {
    if (this.state.inf === '') {
      if (this.props.campos.status === Input) {
        return true
      } else {
        return false
      }
    } else {
      if (this.state.inf === Input) {
        return true
      } else {
        return false
      }
    }
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Alterar status do pedido</h2></ModalHeader>
          <ModalBody>
            <Form style={{ width: '100%' }}>
              <FormGroup style={{ justifyContent: 'justifyContent', width: '100%' }}>
                <Button outline={!this.status('Pedido aceito')} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({ inf: 'Pedido aceito' })
                  }}>
                  Pedido aceito
                </Button>
                {this.props.campos.id_endereco === null ?
                  <Button outline={!this.status('Disponivel para retirada')} color="info" size="sm" disabled={this.state.isLoading2}
                    onClick={async () => {
                      this.setState({ inf: 'Disponivel para retirada' })
                    }}>
                    Disponivel para retirada
                </Button> :
                  <Button outline={!this.status('Saiu para entrega')} color="info" size="sm" disabled={this.state.isLoading2}
                    onClick={async () => {
                      this.setState({ inf: 'Saiu para entrega' })
                    }}>
                    Saiu para entrega
                  </Button>
                }
                <Button outline={!this.status('Pedido entregue')} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({ inf: 'Pedido entregue' })
                  }}>
                  Pedido entregue
                </Button>
                <Button outline={!this.status('Pedido cancelado')} color="info" size="sm" disabled={this.state.isLoading2}
                  onClick={async () => {
                    this.setState({ inf: 'Pedido cancelado' })
                  }} style={{ marginTop: 10 }}>
                  Pedido cancelado
                </Button>
              </FormGroup>
              <FormGroup>
                <h4>Motoboy atribuido: ({this.props.campos.id_motoboy === null ? 'Sem motoboy atribuido' : <label>{this.props.campos.nome_moto} --- <a href="#" onClick={this.handleTiraMoto}>Excluir</a></label>})</h4>
              </FormGroup>
            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="" size="sm" onClick={this.props.Toggle}>
                  Cancelar
                </Button>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
