import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "./assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/argon-dashboard-react.css";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";

import LojasLayout from "./layouts/Lojas";
import AuthLojasLayout from "./layouts/AuthLojas";
import RegistreMotoboy from './layouts/registreMoto';
import ViewPedido2 from './layouts/ViewPedido'

ReactDOM.render(
  <BrowserRouter>
    <Switch>      
      <Route path="/admin" render={props => <AdminLayout {...props} />} />
      <Route path="/loginAdmin" render={props => <AuthLayout {...props} />} /> 
      <Route path="/registre/motoboy" render={props => <RegistreMotoboy {...props} />} /> 
      <Route path="/view/pedido/:id" render={props => <ViewPedido2 {...props} />} /> 
          
      <Route path="/loja" render={props => <LojasLayout {...props} />} />
      <Route path="/login" render={props => <AuthLojasLayout {...props} />} />      
      <Redirect from="*" to="/loja" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
