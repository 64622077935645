
import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col, Alert
} from "reactstrap";

import api from './../../../utlites/api'

class Login extends React.Component {

  state = {
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    username: '',
    senha: ''
  }

  handleLogar = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('username', this.state.username);
      data.append('senha', this.state.senha);

      const response = await api.post('loginAdm', data);


      console.log("resposta ", response.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          if (response.data.login) {
            localStorage.setItem('@admin_app/username',     this.state.username);
            localStorage.setItem('@admin_app/username2',       response.data.nome);
            localStorage.setItem('@admin_app/usernameTipo',    response.data.tipo);
            localStorage.setItem('@admin_app/usernamePass', this.state.senha);
            this.props.history.push('/admin')
          } else {
            this.setState({ isErro: true, erroMotivo: response.data.motivo });
          }
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
        <Col lg="7" md="7" >
          <Card style={{borderColor: '#fd5e36', borderWidth: 1, backgroundColor: '#fd5e36'}}>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small style={{color: 'white'}}>Login</small>
              </div>
              <Form role="form">
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fas fa-user"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Usuário" type="text" name="username" value={this.state.username} onChange={this.handleChange} autoComplete="new-email" />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Senha" name="senha" value={this.state.senha} onChange={this.handleChange} type="password" autoComplete="new-password" />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span style={{color: 'white'}}>Lembre-me</span>
                  </label>
                </div>
                <div className="text-center">
                  <FormGroup>
                    <Button type="submit" disabled={this.state.isLoading} onClick={this.handleLogar} style={{color: '#fd5e36'}} type="button">
                      Logar
                  </Button>
                  </FormGroup>
                </div>
                <div>
                  {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
                  {this.state.isLoading ? <p style={{color: '#fd5e36'}}>Carregando... </p> : null}
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
    );
  }
}

export default Login;
