import React, { Component } from 'react';

import {
  Card,
  CardHeader,
  CardFooter,
  Button,
  Table,
  Container,
  Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem
} from "reactstrap";

import Header from "./../../../components/Headers/Header";
import api from "./../../../utlites/api";
import CadLojas from './CadLojas';
import mascara, { diffDate } from './../../../utlites/mascaraValor'
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import md5 from 'js-md5'
// import { Container } from './styles';

export default class Lojas extends Component {

  state = {
    isLoading: true,
    isLoading3: false,
    isErro: false,
    dados: [],
    dadosOr: [],
    Cadastro: false,
    unidade: [],
    location: {
      latitude: 0,
      longitude: 0
    },
    arr_categorias: [],
    dropdownOpen1: false,
    dropdownOpen2: false,
  }

  campos = {
    id: '',
    nome: '',
    descricao: '',
    name_img: '',
    endereco: '',
    latitude: '-3.044653',
    longitude: '-60.1071907',
    raio: '',
    taxa_entrega: '',
    pedido_minimo: '',
    comissao: '',
    categorias: '',
    franquia_rep: '',
    is_aberto: '',
    unidade_nome: '',
    status: '',
    sid: '',
    selectedFiles: null,
    km_entrega: '',
    tempo_entrega: '',
    telefone: '',
    em_promo: 0,
    valor_desc: 0,
    entrega_free: 0,
    percet_entrega: 0.00,
    tipo_delivery: 'Proprio',
    reduz_entregua: 0
  }

  componentDidMount() {
    //Graças te dou ó meu senho, louvado seja o nome do senhor
    this.getServidor();
    navigator.geolocation.getCurrentPosition(ll => {
      console.log('cxzcz ', ll.coords.latitude);
      this.setState({
        location: {
          latitude: ll.coords.latitude,
          longitude: ll.coords.longitude
        }
      })
    });
  }

  async getServidor() {
    this.setState({ isErro: false, isLoading: true });
    try {

      const data = new FormData();
      data.append('id_user', this.state.idUser);

      const response = await api.get('lojas');
      const resp_unidade = await api.get('unidade');
      const resp_categor = await api.get('categorias');

      console.log("resposta ", resp_categor)
      if (response.status === 200) {
        this.setState({
          dados: response.data.data,
          dadosOr: response.data.data,
          unidade: resp_unidade.data.data,
          arr_categorias: resp_categor.data.data,
        });

      } else {
        this.setState({ isErro: true });
      }

    } catch (error) {
      this.setState({ isErro: true });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  selectFile = event => {
    if (event.target.files[0]) {
      const horas = new Date();
      console.log(`Lojas${localStorage.getItem('@loja_app/loja')}-${horas.getDate()}-${horas.getMonth()}-${horas.getFullYear()}-${horas.getHours()}-${horas.getMinutes()}-${horas.getSeconds()}-${horas.getMilliseconds()}.jpeg`);
      this.setState({
        selectedFiles: event.target.files[0],
        name_img: `Lojas${localStorage.getItem('@loja_app/loja')}-${horas.getDate()}-${horas.getMonth()}-${horas.getFullYear()}-${horas.getHours()}-${horas.getMinutes()}-${horas.getSeconds()}-${horas.getMilliseconds()}.jpeg`
      });
      console.log('cxzcz', md5(event.target.files[0].name))
    } else {
      this.setState({
        selectedFiles: null,
        name_img: ''
      });
    }
  }

  render() {
    return (
      <div>
        <Header />
        {/* Page content */}
        <Container style={{ marginTop: -20, }} fluid>
          {/* Table */}
          <CadLojas
            isVisible={this.state.Cadastro}
            Cancel={() => {
              this.setState({ Cadastro: !this.state.Cadastro })
            }}
            Toggle={(e) => {
              var temp = this.state.dados.map(dados => (dados.id == e.id ? { ...e, carteira: dados.carteira } : dados));
              var temp2 = this.state.dadosOr.map(dados => (dados.id == e.id ? { ...e, carteira: dados.carteira } : dados));

              this.setState({ Cadastro: !this.state.Cadastro, dados: temp, dadosOr: temp2 });
              //this.getServidor()
            }}
            handleChange={this.handleChange}
            campos={this.state}
            atualizaLista={this.getServidor}
            unidades={this.state.unidade}
            atualiza={() => { this.getServidor(); this.setState({ Cadastro: !this.state.Cadastro }) }}
            selectFile={this.selectFile}
            lat={this.state.latitude === '' ? this.state.location.latitude : parseFloat(this.state.latitude)}
            log={this.state.longitude === '' ? this.state.location.longitude : parseFloat(this.state.longitude)}
            getEndereco={(Endereco) => {
              this.setState({ endereco: Endereco })
            }}
            getCoodenadas={(lat, log) => {
              this.setState({ latitude: lat, longitude: log })
            }}
            arr_categorias={this.state.arr_categorias}
            setarCategoria={
              (Input) => {
                var str = String(Input);
                var state = String(this.state.categorias);
                str = str + ', ';

                if (state.includes(str)) {
                  state = state.replace(str, '');
                  this.setState({ categorias: state })
                } else {
                  this.setState({ categorias: state + str })
                }
              }
            }
          />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  {/* Onde vai ficar o botão */}
                  <div id="xxdde" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      size="sm"
                      onClick={async () => {
                        this.setState({
                          ...this.state,
                          ...this.campos,
                          Cadastro: true,
                        })
                      }}
                    >
                      <i class="fas fa-plus"></i>
                      <text>Cadastrar</text>
                    </Button>

                    <div style={{ display: 'flex' }}>
                      <Dropdown size="sm" direction="left" isOpen={this.state.dropdownOpen1} toggle={() => { this.setState({ dropdownOpen1: !this.state.dropdownOpen1 }) }}>
                        <DropdownToggle caret>
                          <i class="fas fa-filter"></i>
                        </DropdownToggle>
                        <DropdownMenu container="xxdde">
                          <DropdownItem onClick={this.filterSaldoMais}>Com saldo positivo</DropdownItem>
                          <DropdownItem onClick={this.filterSaldoMenos}>Com saldo negativo</DropdownItem>
                          <DropdownItem onClick={this.filterAberto}>Lojas abertas</DropdownItem>
                          <DropdownItem onClick={this.filterFechado}>Lojas fechadas</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={this.filterReturn}>Desfazer filtro</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>

                      <Dropdown size="sm" direction="left" isOpen={this.state.dropdownOpen2} toggle={() => { this.setState({ dropdownOpen2: !this.state.dropdownOpen2 }) }}>
                        <DropdownToggle caret>
                          <i class="fas fa-sort-amount-down"></i>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem onClick={() => {
                            var temp = this.state.dadosOr;
                            this.setState({
                              dados: temp.sort((a, b) => (
                                b.carteira - a.carteira
                              ))
                            })
                          }}>Por maior saldo</DropdownItem>
                          <DropdownItem onClick={() => {
                            var temp = this.state.dadosOr;
                            this.setState({
                              dados: temp.sort((a, b) => (
                                a.carteira - b.carteira
                              ))
                            })
                          }}>Por menor saldo</DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => {
                            var temp = this.state.dadosOr;
                            this.setState({
                              dados: temp.sort((a, b) => (
                                a.id - b.id
                              ))
                            })
                          }}>Desfazer ordenação</DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>


                  <Row style={{ alignItems: 'center', marginLeft: 1, fontSize: 12, marginTop: 20 }}>
                    {this.Status(false)}
                      Inativo
                      <div style={{ width: 25 }} />
                    {this.Status(true)}
                      Ativo
                    </Row>
                </CardHeader>

                {this.Corpo()}

                <CardFooter className="py-4">
                  <nav aria-label="...">

                  </nav>
                </CardFooter>
              </Card>
            </div>
          </Row>
        </Container>

      </div>
    );
  }

  Corpo() {
    if (this.state.isLoading) {
      return this.Carregando()
    } else {
      return this.state.isErro ? this.Erro() : this.Listagem()
    }
  }

  Carregando() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Aguarde, carregando...</p></div>
      </div>
    )
  }

  Erro() {
    return (
      <div style={{ width: "100%", alignItems: 'center' }}>
        <div><p style={{ textAlign: "center" }}>Ocorreu um erro na conexão com o servidor 😕</p></div>
      </div>
    )
  }

  Listagem() {
    const urlIMG = new VariaveisGlobais();
    return (
      <Table className="align-items-center table-flush" hover responsive>
        <thead>
          <tr>
            <th scope="col">Id</th>
            <th scope="col" style={{ minWidth: 100 }}>Nome</th>
            <th scope="col" >Endereço</th>
            <th scope="col" ></th>
            <th scope="col">Saldo</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.state.dados.map(i => (
            <tr>
              <th scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {this.Status(i.status === 'Ativo' ? true : false)} {i.id}
                </Row>
              </th>
              <td scope="row">
                <Row style={{ alignItems: 'center' }}>
                  <img src={urlIMG.getHostIMG + "/" + i.name_img} style={{ width: 50, height: 50, marginRight: 15 }} />
                  {i.nome}
                </Row>
              </td>
              <td scope="row">{i.endereco.substr(0, 19)} ...</td>
              <td scope="row">
                {this.state.isLoading3 ? <i class="fas fa-spinner" style={{ color: '#dd9304' }}></i> :
                  <a href="#"
                    onClick={() => {this.MudarStatus(i.id, i.is_aberto)}}
                    style={{ color: i.is_aberto === 'Aberto' ? '#D4EDDA' : '#F8D7DA' }}>{i.is_aberto === 'Aberto' ? <i class="fas fa-door-open"></i> : <i class="fas fa-door-closed"></i>}</a>
                }

              </td>
              <td scope="row">R$ {i.carteira >= 0 ? '' : '-'} {mascara(eval(parseFloat(i.carteira)).toFixed(2))}</td>
              <td scope="row">
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.setState({
                    ...this.state,
                    ...i,
                    selectedFiles: null,
                    Cadastro: true,
                  })
                }}>Editar</Button>
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.props.history.push({
                    pathname: '/admin/loja/carteira',
                    input: { id: i.id, nome: i.nome },
                  })
                }}>Carteira</Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }

  filterSaldoMais = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.carteira > 0
      ))
    })
  }

  filterSaldoMenos = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.carteira < 0
      ))
    })
  }

  filterAberto = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.is_aberto === 'Aberto'
      ))
    })
  }

  filterFechado = () => {
    var temp = this.state.dadosOr;
    this.setState({
      dados: temp.filter((a) => (
        a.is_aberto !== 'Aberto'
      ))
    })
  }

  filterReturn = () => {
    var temp = this.state.dadosOr;
    this.setState({ dados: temp })
  }

  Status(isativo) {
    return (
      <div style={{
        width: 15,
        height: 15,
        backgroundColor: isativo ? '#D4EDDA' : '#F8D7DA',
        borderRadius: 50,
        marginRight: 10
      }}>

      </div>
    )
  }

  async MudarStatus (id, statusAtu) {
    //card_ckmnw53e002hz0h9t8161vshg
    this.setState({ isLoading3: true });
    try {
      const data = new FormData();
      data.append('id', id);
      data.append('is_aberto', statusAtu === 'Aberto' ? 'Fechado' : 'Aberto');

      var response = await api.post(`lojas/mudarStatus`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response);
        } else {
          var temp = this.state.dados.map(dados => (dados.id == id ? { ...dados, is_aberto: statusAtu === 'Aberto' ? 'Fechado' : 'Aberto' } : dados));
          var temp2 = this.state.dadosOr.map(dados => (dados.id == id ? { ...dados, is_aberto: statusAtu === 'Aberto' ? 'Fechado' : 'Aberto' } : dados));

          this.setState({ isLoading3: false, dados: temp, dadosOr: temp2 });
        }

      } else {
        this.setState({ isLoading3: false });
      }

    } catch (error) {
      this.setState({ isLoading3: false });
    }
    this.setState({ isLoading3: false });
  }

}