import React, { Component } from 'react';
import { NavLink as NavLinkRRD, Link } from "react-router-dom";
import {
  NavItem,
  NavLink,
  Nav,
} from "reactstrap";

import routes_lojas_cadastro from './../../routes_lojas_cadastro'

export default class Menus_lojas extends Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }

  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  }

  createLinks = routes => {
    return routes_lojas_cadastro.map((prop, key) => {

      if (prop.type === 'separat') {
        return(<hr className="my-3" style={{marginLeft: 25, marginRight: 25}}/>)
      } else {

        return (
          <NavItem key={key}>
            <NavLink
              to={prop.layout + prop.path}
              tag={NavLinkRRD}
              onClick={this.props.fechar}
              activeClassName="active"
            >
              <i className={prop.icon} />
              {prop.name}
            </NavLink>
          </NavItem>
        );
      }
    }
    );

  };

  render() {
    return (
      <div>
        <hr className="my-3" />
        <h6 className="navbar-heading text-muted">Menu</h6>
        <Nav navbar>
          <NavItem key={0}>
            {this.createLinks(routes_lojas_cadastro)}
          </NavItem>
        </Nav>
        {/* Divider */}
        <hr className="my-3" />
      </div>
    );
  }
}
