import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert
} from 'reactstrap';
import api from "./../../../utlites/api";
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
// import { Container } from './styles';

export default class CadUnidade extends Component {


  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: ''
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    if (localStorage.getItem('@admin_app/usernameTipo') === 'Admin') {
      this.setState({ isErro: false, isLoading: true });
      try {
        const data = new FormData();
        data.append('id', this.props.campos.id);
        data.append('nome_completo', this.props.campos.nome_completo);
        data.append('cpf', this.props.campos.cpf);
        data.append('rg', this.props.campos.rg);
        data.append('telefone', this.props.campos.telefone);
        data.append('endereco', this.props.campos.endereco);
        data.append('bairro', this.props.campos.bairro);
        data.append('tipo_veiculo', this.props.campos.tipo_veiculo);
        data.append('placa_veiculo', this.props.campos.placa_veiculo);
        data.append('chn', this.props.campos.chn);
        data.append('indicacao', this.props.campos.indicacao);
        data.append('observacao', this.props.campos.observacao);
        data.append('status', this.props.campos.status);
        data.append('unidade_rep', this.props.campos.unidade_rep);

        var response;
        if (this.props.campos.id === '') {
          response = await api.put('motoboy', data);
        } else {
          response = await api.post('motoboy', data);
        }

        console.log("resposta ", response.data)
        if (response.status === 200) {
          if (response.data.error) {
            console.log('Erro: ', response.data.data)
            this.setState({ isErro: true, erroMotivo: response.data.message });
          } else {
            this.props.Toggle(response.data.data);
          }

        } else {
          this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
        }

      } catch (error) {
        this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
        console.log('Erro ', error)
      }
      this.setState({ isLoading: false });
    } else {
      alert('Usuário sem acesso!')
    }
  }

  handleExcluir = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id', this.props.campos.id);

      var response = await api.delete(`motoboy/${this.props.campos.id}`, data);

      console.log("resposta ", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response)
          this.setState({ isErro: true, erroMotivo: `Erro interno` });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  Link(x) {
    window.open(x, '_blank')
  }

  render() {
    const urlIMG = new VariaveisGlobais();
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Cadastro de motoboy</h2></ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label>Id</Label>
                <Input type="text" disabled={true} size="sm" name="nome" value={this.props.campos.id} />
              </FormGroup>

              {this.props.campos.id !== '' ?
                <FormGroup>
                  <a href="#" onClick={() => { this.Link(`${urlIMG.getHostIMG}/imageFoto${this.props.campos.token}.jpeg`) }} style={{ marginRight: 15 }}>Foto</a>
                  <a href="#" onClick={() => { this.Link(`${urlIMG.getHostIMG}/imageCNH${this.props.campos.token}.jpeg`) }} style={{ marginRight: 15 }}> RG / CNH</a>
                  <a href="#" onClick={() => { this.Link(`${urlIMG.getHostIMG}/imageComp${this.props.campos.token}.jpeg`) }} style={{ marginRight: 15 }}>Comprovante de residência</a>
                </FormGroup>
                : null}

              <FormGroup>
                <Label>Nome *</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="nome_completo"
                  value={this.props.campos.nome_completo} onChange={this.props.handleChange} required />
              </FormGroup>

              <FormGroup>
                <Label>CPF</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="cpf"
                  value={this.props.campos.cpf} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>RG</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="rg"
                  value={this.props.campos.rg} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Telefone *</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="telefone"
                  value={this.props.campos.telefone} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Endereco</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="endereco"
                  value={this.props.campos.endereco} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Bairro</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="bairro"
                  value={this.props.campos.bairro} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Tipo veiculo</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="tipo_veiculo"
                  value={this.props.campos.tipo_veiculo} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Placa veiculo</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="placa_veiculo"
                  value={this.props.campos.placa_veiculo} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Cnh</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="chn"
                  value={this.props.campos.chn} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Indicação</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="indicacao"
                  value={this.props.campos.indicacao} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Observação</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="observacao"
                  value={this.props.campos.observacao} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Franquia reponsável *</Label>
                <Input type="select" name="unidade_rep" id="exampleSelect" value={this.props.campos.unidade_rep} onChange={this.props.handleChange}>
                  <option value={-1}></option>
                  {this.props.unidades.map(i => (
                    <option value={i.id}>{i.nome}</option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Status</Label>
                <Input type="select" name="status" id="exampleSelect" value={this.props.campos.status} onChange={this.props.handleChange}>
                  <option value="Inativo">Inativo</option>
                  <option value="Ativo">Ativo</option>
                </Input>
              </FormGroup>

            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                {this.props.campos.id !== "" ?
                  <Button disabled={this.state.isLoading} color="danger" size="sm">
                    Excluir
                  </Button> : null
                }
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Cancel()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
