import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert
} from 'reactstrap';
import api from "./../../../utlites/api";
import VariaveisGlobais from './../../../utlites/variaveisDoSistema'
import moment from 'moment-timezone'
// import { Container } from './styles';

export default class LancCarteira extends Component {


  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    tipo: 'Credito'
  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();

      var vlTemp = this.state.tipo === 'Debito' ? '-'+(this.props.campos.valor) : (this.props.campos.valor)
     
      data.append('descricao', this.props.campos.descricao);
      data.append('data', moment.tz(this.props.campos.data).format("YYYY-MM-DD HH:mm:ss"));
      data.append('pedido', this.props.campos.pedido);
      data.append('valor', String(vlTemp).replace(",", "."));
      data.append('id_motoboy', this.props.idmotoboy);

      var response;
      if (this.props.campos.id === '') {
        response = await api.post('admin/motoboy/setCart', data);
      } 

      console.log("resposta ", response.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.Toggle();
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };
  
  render() {
    const urlIMG = new VariaveisGlobais();
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Lançamento financeiro motoboy</h2></ModalHeader>
          <ModalBody>
            <Form>            
              <FormGroup>
                <Label>Descrição *</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="descricao"
                  value={this.props.campos.descricao} onChange={this.props.handleChange} required />
              </FormGroup>

              <FormGroup>
                <Label>Data *</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="data"
                  value={this.props.campos.data} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Valor *</Label>
                <Input type="text" disabled={this.state.isLoading} size="sm" name="valor"
                  value={this.props.campos.valor} onChange={this.props.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Tipo</Label>
                <Input type="select" name="tipo" id="exampleSelect" value={this.state.tipo} onChange={this.handleChange}>
                  <option value="Debito">Debito</option>
                  <option value="Credito">Credito</option>
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Toggle()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
