import React, { Component, useState } from 'react';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter,
  Form, FormGroup, Label, Input, Alert, Table,
  Container,
  Row
} from 'reactstrap';
import api from "./../../../utlites/api";
// import { Container } from './styles';

export default class Montagem extends Component {

  state = {
    modal: false,
    backdrop: true,
    keyboard: true,
    isLoading: false,
    isErro: false,
    erroMotivo: '',
    isLoading2: false,
    id: '',
    nome: '',
    id_produto: '',
    quantidade: '',
    id_grupo: '',
    obriga: 'Não',
    tipo_preco: 'Normal',

  }

  changeBackdrop = e => {
    let value = e.target.value;
    if (value !== 'static') {
      value = JSON.parse(value);
    }
    this.setState({ backdrop: value })
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  changeKeyboard = e => {
    this.setState({ keyboard: e.currentTarget.checked })
  }

  handleCadastro = async () => {
    this.setState({ isErro: false, isLoading: true });
    try {
      const data = new FormData();
      data.append('id_produto', this.props.idProd);
      data.append('nome', this.state.nome);
      data.append('quantidade', this.state.quantidade);
      data.append('id_grupo', this.state.id_grupo);
      data.append('obriga', this.state.obriga);
      data.append('tipo_preco', this.state.tipo_preco);

      var response = await api.put('motagem', data);

      console.log("resposta dee", response.data.data)
      if (response.status === 200) {
        if (response.data.error) {
          console.log('Erro: ', response.data.data)
          this.setState({ isErro: true, erroMotivo: response.data.message });
        } else {
          this.props.up({
            id: response.data.data,
            nome: this.state.nome,
            id_produto: this.props.idProd,
            quantidade: this.state.quantidade,
            id_grupo: this.state.id_grupo,
            obriga: this.state.obriga,
            tipo_preco: this.state.tipo_preco,
          })
        }

      } else {
        this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
      }

    } catch (error) {
      this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
      console.log('Erro ', error)
    }
    this.setState({ isLoading: false });
  }

  render() {
    return (
      <div>
        <Modal isOpen={this.props.isVisible} toggle={this.Toggle} className={this.props.className} backdrop={this.state.backdrop} keyboard={this.state.keyboard}>
          <ModalHeader toggle={this.toggle}><h2>Cadastro de grupo de produto</h2></ModalHeader>
          <ModalBody>
            <Form>

              <FormGroup>
                <Label>Nome (*)</Label>
                <Input type="text" disabled={this.state.isLoading} name="nome" value={this.state.nome} onChange={this.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label>Quantidade maxima</Label>
                <Input type="text" disabled={this.state.isLoading} name="quantidade" value={this.state.quantidade} onChange={this.handleChange} />
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Grupo (*)</Label>
                <Input type="select" disabled={this.state.isLoading} name="id_grupo" id="exampleSelect" value={this.state.id_grupo} onChange={this.handleChange}>
                  <option value="-1"></option>
                  {this.props.grupos.map(i => (
                    <option value={i.id}>{i.nome}</option>
                  ))}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Tipo de preço</Label>
                <Input type="select" name="tipo_preco" id="exampleSelect" value={this.state.tipo_preco} onChange={this.handleChange} disabled={this.state.isLoading}>
                  <option value="Normal">Normal</option>
                  <option value="Maior">Maior</option>
                  <option value="Divisão">Divisão</option>
                </Input>
              </FormGroup>

              <FormGroup>
                <Label for="exampleSelect">Obrigatório</Label>
                <Input type="select" name="obriga" id="exampleSelect" value={this.state.obriga} onChange={this.handleChange} disabled={this.state.isLoading}>
                  <option value="Não">Não</option>
                  <option value="Sim">Sim</option>
                </Input>
              </FormGroup>
              {this.listagem()}
            </Form>
          </ModalBody>
          <div style={{ marginLeft: 25, marginRight: 25 }}>
            {this.state.isErro ? <Alert color="warning">{this.state.erroMotivo}</Alert> : null}
          </div>
          <ModalFooter>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
              <div>
                {this.state.isLoading ? <p>Carregando... </p> : null}
              </div>
              <div>
                <Button disabled={this.state.isLoading} color="success" size="sm" onClick={this.handleCadastro}>
                  Salvar
                </Button>
                <Button disabled={this.state.isLoading} color="secondary" size="sm" onClick={
                  () => {
                    this.setState({ isErro: false })
                    this.props.Toggle()
                  }
                }>
                  Cancelar
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }

  listagem() {
    return (
      <Table className="align-items-center table-flush" hover responsive>
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Qtd</th>
            <th scope="col">Grupo</th>
            <th scope="col">Obg</th>
            <th scope="col">Ação</th>
          </tr>
        </thead>
        <tbody>
          {this.props.montagem.map((i, key) => (
            <tr>
              <th scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {i.nome}
                </Row>
              </th>
              <td scope="row">
                <Row style={{ alignItems: 'center' }}>
                  {i.quantidade}
                </Row>
              </td>
              <td scope="row">{i.nome_grupo}</td>
              <td scope="row">{i.obriga}</td>
              <td scope="row">
                <Button outline color="warning" size="sm" onClick={async () => {
                  this.setState({isLoading2: true });
                  try {
                    
                    var response = await api.delete(`motagem/${i.id}`);

                    console.log("resposta dee", response.data.data)
                    if (response.status === 200) {
                      if (response.data.error) {
                        console.log('Erro: ', response.data.data)
                      } else {
                        this.props.del(key)
                      }

                    } else {
                      this.setState({ isErro: true, erroMotivo: `Erro HTTP: ${response.status}` });
                    }

                  } catch (error) {
                    this.setState({ isErro: true, erroMotivo: `Erro: ${error}` });
                    console.log('Erro ', error)
                  }
                  this.setState({ isLoading2: false });
                }}>
                  {this.state.isLoading2 ? <i className="fas fa-spinner" /> : <i className="far fa-trash-alt" />}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    )
  }
}
